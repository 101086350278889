import { Modal, Box, Backdrop, TextField } from "@mui/material";
import { styles } from "../../styles";
import useClasses from "../../useClasses";
import {
  StyledGreenButton,
  StyledRedButton,
  StyledGrayButton,
} from "../StyledButtons";
import { StyledHeader } from "../StyledHeader";

export const ApproveQuotationModal = (props) => {
  const classes = useClasses(styles);
  const { open, close, confirm, approvalReason, setApprovalReason } = props;
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          className: classes.backdrop,
        },
      }}
    >
      <Box className={classes.edit_container}>
        <StyledHeader style={{ paddingTop: "0", fontSize: "1.2em" }}>
          Confirm Approve / Reject
        </StyledHeader>
        <TextField
          label="Reason"
          variant="outlined"
          value={approvalReason}
          onChange={(e) => setApprovalReason(e.target.value)}
          style={{ flex: 1, width: "100%" }}
          multiline
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "1em",
          }}
        >
          <StyledGreenButton
            onClick={() => confirm("APPROVED")}
            style={{ marginRight: "1em" }}
          >
            Approve
          </StyledGreenButton>
          <StyledRedButton
            onClick={() => confirm("REJECTED")}
            style={{ marginRight: "1em" }}
          >
            Reject
          </StyledRedButton>
          <StyledGrayButton onClick={close}>Cancel</StyledGrayButton>
        </Box>
      </Box>
    </Modal>
  );
};
