import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment,
  IconButton,
  styled,
  Grid,
} from "@mui/material";
import BasicTable from "../components/QuotationTable";
import { Check, Search } from "@mui/icons-material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  error_code,
  getToday,
  invalidateCredentials,
  validateSession,
} from "../utils";
import * as XLSX from "xlsx";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import {
  StyledBlueButton,
  StyledGrayButton,
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ApprovalSubmissionModal } from "../components/modal/ApprovalSubmissionModal";
import { ApproveQuotationModal } from "../components/modal/ApproveQuotationModal";
import { NotificationContext } from "../components/NotificationProvider";

const StyledBox = styled(Box)(
  ({ theme }) => `
        border-radius: 10px;
        padding: 2em 1em;
        box-shadow: 0px 0px 30px 2px rgba(212, 212, 212, 0.3);
    `
);

const CreateQuotation = () => {
  let navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [createNew, setCreateNew] = useState(true);
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [attention, setAttention] = useState({
    contact_name: "",
    office_number: "",
    extension: "",
    phone: "",
  });
  const [reference, setReference] = useState("");
  const [status, setStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState([
    "BIDDING",
    "CONFIRMED",
    "R&D STAGE",
  ]);

  const [phone, setPhone] = useState("");
  const [extension, setExtension] = useState("");
  const [mobile, setMobile] = useState("");

  const [date, setDate] = useState("");
  const [accountManager, setAccountManager] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([
    "USD",
    "CNY",
    "MYR",
    "THB",
    "PHP",
  ]);
  const [payment, setPayment] = useState("");
  const [shipment, setShipment] = useState("");
  const [validity, setValidity] = useState("30 DAYS");
  const [revision, setRevision] = useState("A");
  const [remarks, setRemarks] = useState("-");
  const [quotationCreator, setQuotationCreator] = useState("");

  const [attentionOptions, setAttentionOptions] = useState([]);

  // item information
  const [lineItems, setLineItems] = useState([]);
  const [role, setRole] = useState("STAFF");
  const [isApproved, setIsApproved] = useState(null);
  const [openApprove, setOpenApprove] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("PENDING");
  const [approvalReason, setApprovalReason] = useState("");
  const [submitApproval, setSubmitApproval] = useState(false);

  const [lastEditDate, setLastEditDate] = useState("N/A");
  const [lastEditor, setLastEditor] = useState("N/A");

  const [currentEditor, setCurrentEditor] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [creator, setCreator] = useState(Cookies.get("employee", null));

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();

    // Retrieve data from sessionStorage
    const reportQuotationId =
      sessionStorage.getItem("reportQuotationId") || "Default Value";
    const report = sessionStorage.getItem("report") === "true";
    if (report) {
      setCreateNew(false);
      setNumber(reportQuotationId);
      performQuotationSearch(reportQuotationId);
      sessionStorage.clear();
    } else {
      const date = new Date();
      const day = date.getDate().toString().padStart(2, "0");
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      setDate(formattedDate);
      generateQuotationNumber();
    }
  }, []);

  const generateQuotationNumber = async () => {
    let generatedQuotation = "";
    await axios
      .get(`${API_GATEWAY_URL}/quotation/generate`)
      .then((response) => {
        const [prefix, number] = response.data.split("-");
        const newNumber = parseInt(number, 10);
        const num = newNumber;
        generatedQuotation = `${prefix}-${num}`;
        setNumber(generatedQuotation);
      })
      .catch((error) => {
        alert(error);
      });
    return generatedQuotation;
  };

  const setQuotationInfo = (data) => {
    if (Object.keys(data).length === 0) {
      showNotification(
        "error",
        "Authorization Error: You don't have the permission to view this quotation"
      );
      window.location.reload();
      return;
    }
    setNumber(data.quotation_id.toUpperCase());
    setDate(data.date);
    setCompany(data.company_name);
    setAttention({
      contact_name: data.contact_name,
      office_number: data.office_number,
      extension: data.extension,
      phone: data.phone,
    });
    setAttentionOptions([
      {
        contact_name: data.contact_name,
        office_number: data.office_number,
        extension: data.extension,
        phone: data.phone,
      },
    ]);
    setPhone(data.office_number);
    setExtension(data.extension);
    setMobile(data.phone);
    setReference(data.reference);
    setStatus(data.status);
    setAccountManager(data.account_manager);
    setCurrency(data.currency);
    setShipment(data.shipment_terms);
    setPayment(data.payment_terms);
    setValidity(data.validity);
    setRevision(data.revision);
    setRemarks(data.remarks);
    setQuotationCreator(data.creator);

    setLastEditor(data.last_editor ? data.last_editor : "N/A");
    setLastEditDate(data.last_update ? data.last_update : "N/A");

    let line_items = data.line_items;
    let items = [];
    line_items.sort((a, b) => {
      return a.line_number - b.line_number;
    });
    for (let line_item of line_items) {
      items.push({
        line_number: line_item.line_number,
        cpn: line_item.cpn,
        mpn: line_item.mpn,
        description: line_item.description,
        manufacturer: line_item.manufacturer,
        annual_usage: line_item.annual_usage,
        project: line_item.project,
        build_site: line_item.build_site,
        spq: line_item.spq,
        packaging: line_item.packaging,
        moq: line_item.moq,
        customer_price: line_item.customer_price,
        lead_time: line_item.lead_time,
        cancellation: line_item.cancellation,
        target_price: line_item.target_price,
        supplier_price: line_item.supplier_price,
        updates: line_item.updates,
        last_update: line_item.last_update,
      });
    }
    getApproval(data.quotation_id.toUpperCase());
    setLineItems(items);
  };

  const performQuotationSearch = async (quotationId) => {
    // search all quotations
    const searchId = quotationId || number.trim();
    await axios
      .get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: searchId,
          creator: creator,
          email: Cookies.get("email", ""),
        },
      })
      .then(function (response) {
        if ("error_code" in response.data) {
          showNotification("error", error_code[response.data.error_code]);
        } else {
          setQuotationInfo(response.data);
          setCreateNew(false);
        }
      })
      .catch(function (error) {
        showNotification("error", error);
      });
  };

  const performCompanySearch = (term) => {
    // search all companies
    axios
      .post(`${API_GATEWAY_URL}/company/search`, {
        company: company.toUpperCase(),
        language: "english",
        type: "like",
      })
      .then(function (response) {
        if (Object.keys(response.data).length !== 0) {
          setCompany(response.data.company);
          setAccountManager(response.data.account_manager);
          setShipment(response.data.shipment_terms);
          setPayment(response.data.payment_terms);
          setCompanyType(response.data.type);
          // search for available contacts based on company
          axios
            .get(`${API_GATEWAY_URL}/contact`, {
              params: {
                company: response.data.company,
              },
            })
            .then(function (response) {
              setAttentionOptions(response.data);
            })
            .catch(function (error) {
              showNotification(
                "error",
                "Error retrieving contact information, try again later"
              );
            });
        } else {
          // do something else here
          showNotification("error", "Company does not exist");
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const handleSaveButton = async () => {
    try {
      const response = await axios.get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: number,
          creator: creator,
          role: Cookies.get("role", null),
        },
      });
      if ("error" in response.data) {
        showNotification("error", error_code[response.data.error_code]);
        return false;
      }
      if (response.data.error_code == 444 || number === "") {
        return true;
      }
      return true;
    } catch (error) {
      showNotification("error", error);
      return false;
    }
  };

  const CreateNewQuotation = async () => {
    let currentNumber = number;
    let duplicateNumber = false;
    let authorized = await axios
      .get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: number,
          creator: creator,
          role: Cookies.get("role", null),
          email: Cookies.get("email", ""),
        },
      })
      .then(async function (response) {
        if ("error" in response.data) {
          showNotification("error", error_code[response.data.error_code]);
          return false;
        }
        if (response.data.error_code == 444) {
          return true;
        }
        if (response.data.quotation_id == currentNumber) {
          currentNumber = await generateQuotationNumber();
          setNumber(currentNumber);
          duplicateNumber = true;
          return true;
        }
      })
      .catch(function (error) {
        showNotification("error", error);
        return false;
      });
    if (!authorized) {
      return;
    }
    setCreateNew(false);
    SaveQuotationDetails(currentNumber, duplicateNumber);
  };

  const SaveQuotation = async () => {
    // perform search
    let authorized = await axios
      .get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: number,
          creator: creator,
          role: Cookies.get("role", null),
        },
      })
      .then(async function (response) {
        if ("error" in response.data || response.data.error_code == 444) {
          showNotification("error", error_code[response.data.error_code]);
          return false;
        }
        return true;
      })
      .catch(function (error) {
        showNotification("error", error);
        return false;
      });
    if (!authorized) {
      return;
    }
    SaveQuotationDetails(number);
  };

  const SaveQuotationDetails = (number, isNew) => {
    let editTime = getToday();
    // save quotation in database
    let body = {
      quotation_id: number,
      date: date,
      // company
      company_name: company,
      account_manager: accountManager,
      shipment_terms: shipment,
      payment_terms: payment,
      // contact
      contact_name: attention.contact_name,
      office_number: attention.office_number,
      extension: attention.extension,
      phone: attention.phone,
      // others
      currency: currency,
      reference: reference,
      status: status,
      validity: validity,
      revision: revision,
      remarks: remarks,
      line_items: [],
      last_editor: currentEditor,
      last_update: editTime,
      type: companyType,
      creator: creator,
      role: Cookies.get("role", null),
    };
    let line_items = [];
    for (let i = 0; i < lineItems.length; i++) {
      // format into correct row
      let line_item = lineItems[i];
      let row = {
        line_number: i + 1,
        manufacturer: line_item.manufacturer,
        mpn: line_item.mpn,
        annual_usage: line_item.annual_usage,
        cpn: line_item.cpn,
        description: line_item.description,
        moq: line_item.moq,
        customer_price: line_item.customer_price,
        spq: line_item.spq,
        packaging: line_item.packaging,
        lead_time: line_item.lead_time,
        cancellation: line_item.cancellation,
        build_site: line_item.build_site,
        project: line_item.project,
        target_price: line_item.target_price,
        supplier_price: line_item.supplier_price,
        updates: line_item.updates,
        last_update: line_item.last_update,
      };
      line_items.push(row);
    }
    body.line_items = line_items;
    axios
      .post(`${API_GATEWAY_URL}/quotation/save`, body)
      .then(async (response) => {
        isNew
          ? showNotification(
              "warning",
              "Existing quote number exists, quote number will be update to " +
                number
            )
          : showNotification("success", "Successfully saved quotation!");
        setLastEditor(currentEditor);
        setLastEditDate(editTime);
      })
      .catch((error) => {
        showNotification(
          "error",
          "Error saving quotation, please try again later"
        );
      });
  };

  const ExportPDF = async () => {
    let body = {
      quotation_id: number,
      date: date,
      company_name: company,
      contact_name: attention.contact_name,
      currency: currency,
      reference: reference,
      status: status,
      validity: validity,
      account_manager: accountManager,
      payment_terms: payment,
      shipment_terms: shipment,
      revision: revision,
      remarks: remarks,
      line_items: [],
    };
    let line_items = [];
    for (let i = 0; i < lineItems.length; i++) {
      // format into correct row
      let line_item = lineItems[i];
      let row = {
        line_no: (i + 1).toString(),
        manufacturer: line_item.manufacturer,
        mpn: line_item.mpn,
        description: line_item.description,
        moq: line_item.moq.toString(),
        annual_usage: line_item.annual_usage.toString(),
        cpn: line_item.cpn,
        build_site: line_item.build_site,
        project: line_item.project,
        target_price: line_item.target_price.toString(),
        updates: line_item.updates,
        spq: line_item.spq.toString(),
        packaging: line_item.packaging,
        customer_price: line_item.customer_price.toString(),
        lead_time: line_item.lead_time.toString(),
        cancellation: line_item.cancellation.toString(),
      };
      line_items.push(row);
    }
    if (line_items.length === 0) {
      alert("Please ensure there is at least 1 line item");
      return;
    }
    body.line_items = line_items;
    try {
      let response = await axios.post(`${API_GATEWAY_URL}/quotation`, body);
      // Convert Base64 string to a Blob
      const blob = base64ToBlob(response.data, "application/pdf");

      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(blob);

      // Create a temporary anchor tag and set the download attribute
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = number + ".pdf";
      document.body.appendChild(a);

      // Trigger the download
      a.click();

      // Clean up by removing the anchor tag and revoking the Blob URL
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      showNotification(
        "error",
        "There was an error downloading PDF, try again later!"
      );
    }
  };

  const base64ToBlob = (base64, mimeType) => {
    // Decode Base64 string
    const byteCharacters = atob(base64); // Remove base64 header

    // Generate a byte array from the binary data
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Create and return a new Blob using the byte array
    return new Blob([byteArray], { type: mimeType });
  };

  const ExportInternalUse = () => {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();

    let line_items = [];
    for (let i = 0; i < lineItems.length; i++) {
      // format into correct row
      let line_item = lineItems[i];
      let row = {
        "Line No": (i + 1).toString(),
        "CPN ( 30max )": line_item.cpn,
        "MPN ( 30max )": line_item.mpn,
        "Description ( 40max )": line_item.description,
        "Brand ( 20max )": line_item.manufacturer,
        "Usage (10)": line_item.annual_usage.toString(),
        "Project ( 25max)": line_item.project,
        "Build Site (20max)": line_item.build_site,
        SPQ: line_item.spq.toString(),
        MOQ: line_item.moq.toString(),
        CPrice: line_item.customer_price.toString(), // need to verify
        "Sup.Curr": currency,
        SPrice: line_item.supplier_price.toString(),
        "LT ( wks )": line_item.lead_time.toString(),
        "Cancel ( wks )": line_item.cancellation.toString(),
        "Target Price": line_item.target_price.toString(),
        Business: "",
        Packaging: line_item.packaging,
        "Follow-Up update (  100max )": line_item.updates,
      };
      line_items.push(row);
    }

    const ws = XLSX.utils.json_to_sheet(line_items);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook
    XLSX.writeFile(wb, `${number}-internal.xlsx`);
  };

  const ExportExcel = () => {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();

    let line_items = [];
    for (let i = 0; i < lineItems.length; i++) {
      // format into correct row
      let line_item = lineItems[i];
      let row = {
        "Quote No.": number,
        "Line No": (i + 1).toString(),
        "Customer Part No. (CPN)": line_item.cpn,
        Manufacturer: line_item.manufacturer,
        "Mfr Part No. (MPN)": line_item.mpn,
        Description: line_item.description,
        Usage: line_item.annual_usage.toString(),
        SPQ: line_item.spq.toString(),
        Packaging: line_item.packaging,
        MOQ: line_item.moq.toString(),
        Currency: currency,
        "Unit Price": line_item.customer_price.toString(),
        "Lead time (wks)": line_item.lead_time.toString(),
        "Cancel (wks)": line_item.cancellation.toString(),
      };
      line_items.push(row);
    }

    const ws = XLSX.utils.json_to_sheet(line_items);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook
    XLSX.writeFile(wb, `${number}.xlsx`);
  };

  const handleLineItemImport = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file == null) {
      return;
    }
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      let newLineItems = [];
      let i = 0;
      for (let line_item of jsonData) {
        let values = Object.values(line_item);

        let cpn =
          "CPN" in line_item ? line_item["CPN"].toString().toUpperCase() : "";
        let mpn =
          "MPN" in line_item ? line_item["MPN"].toString().toUpperCase() : "";
        let description =
          "Description" in line_item
            ? line_item["Description"].toString().toUpperCase()
            : "";
        let manufacturer =
          "Manufacturer" in line_item
            ? line_item["Manufacturer"].toString().toUpperCase()
            : "";
        let annual_usage =
          "Usage" in line_item ? parseInt(line_item["Usage"]) : 0;
        let project =
          "Project" in line_item
            ? line_item["Project"].toString().toUpperCase()
            : "";
        let build_site =
          "Build Site" in line_item
            ? line_item["Build Site"].toString().toUpperCase()
            : "";
        let spq = "SPQ" in line_item ? line_item["SPQ"] : "";
        let moq = "MOQ" in line_item ? line_item["MOQ"] : "";
        let customer_price = "Cprice" in line_item ? line_item["Cprice"] : "";
        let currency =
          "Supp. Curr" in line_item
            ? line_item["Supp. Curr"].toString().toUpperCase()
            : "";
        let supplier_price = "Sprice" in line_item ? line_item["Sprice"] : "";
        let lead_time =
          "LT ( wks )" in line_item ? line_item["LT ( wks )"] : "";
        let cancellation =
          "Cancel ( wks )" in line_item ? line_item["Cancel ( wks )"] : "";
        let target_price =
          "Target Price" in line_item ? line_item["Target Price"] : "";
        let packaging =
          "Packaging" in line_item
            ? line_item["Packaging"].toString().toUpperCase()
            : "";
        let updates =
          "Follow Up Update" in line_item
            ? line_item["Follow Up Update"].toString().toUpperCase()
            : "";
        let last_update = getToday();

        newLineItems.push({
          cpn,
          mpn,
          description,
          manufacturer,
          annual_usage,
          project,
          build_site,
          spq,
          moq,
          customer_price,
          currency,
          supplier_price,
          lead_time,
          cancellation,
          target_price,
          packaging,
          updates,
          last_update,
        });
      }
      //setLineItems([...lineItems, ...newLineItems]);
      setLineItems(newLineItems);
    };
    reader.readAsBinaryString(file);
  };

  const confirmApprove = (status) => {
    // call approve api
    axios
      .post(`${API_GATEWAY_URL}/quotation/approve`, {
        quotation_id: number,
        status: status,
        reason: approvalReason,
        approver: creator,
      })
      .then((resp) => {
        // successful approval
        showNotification("success", resp.data);
        // update approval status
        axios
          .post(`${API_GATEWAY_URL}/quotation/approve/notify`, {
            quotation_id: number,
            status: status,
            reason: approvalReason,
            creator: quotationCreator,
          })
          .then((resp) => {
            console.log("Notified successfully");
          })
          .catch((error) => {
            console.log("Failed to notify");
          });
        getApproval(number);
        setOpenApprove(false);
      })
      .catch((error) => {
        // unsucessful approval, display error
        showNotification("error", error.response.data);
      });
  };

  const getApproval = (number) => {
    axios
      .get(`${API_GATEWAY_URL}/quotation/approve`, {
        params: {
          quotation_id: number,
        },
      })
      .then((resp) => {
        setApprovalStatus(resp.data.status);
        setApprovalReason(resp.data.reason);
      })
      .catch((error) => {
        // unsucessful approval, display error
        showNotification("error", error.response.data);
      });
  };

  const confirmSubmitApproval = async () => {
    let authorized = await axios
      .get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: number,
          creator: creator,
          role: Cookies.get("role", null),
        },
      })
      .then(function (response) {
        if ("error" in response.data) {
          showNotification("error", error_code[response.data.error_code]);
          if (response.data.error_code == 444) {
            return true;
          }
          return false;
        }
        return true;
      })
      .catch(function (error) {
        showNotification("error", error);
        return false;
      });
    if (!authorized) {
      return;
    }
    // confirm submission
    // call webhook
    axios
      .post(`${API_GATEWAY_URL}/quotation/submit-approval-update`, {
        quotation_id: number,
        status: "PENDING APPROVAL",
      })
      .then((resp) => {
        axios
          .post(`${API_GATEWAY_URL}/quotation/submit-approval`, {
            quotation_id: number,
            creator: creator,
          })
          .then((resp) => {
            showNotification(
              "success",
              "Quotation has been submitted for approval"
            );
            setSubmitApproval(!submitApproval);
            getApproval(number);
          })
          .catch((error) => {
            showNotification(
              "error",
              "Error submitting approval, please try again later"
            );
            setSubmitApproval(!submitApproval);
          });
      })
      .catch((error) => {
        showNotification(
          "error",
          "Error submitting approval, please try again later"
        );
        setSubmitApproval(!submitApproval);
      });
  };

  const handleAttentionChange = (e) => {
    const selectedOption = attentionOptions.find(
      (option) => option.contact_name === e.target.value
    );
    setAttention(selectedOption);
    setPhone(selectedOption.office_number);
    setExtension(selectedOption.extension);
    setMobile(selectedOption.phone);
  };

  const goForward = async () => {
    // search
    axios
      .get(`${API_GATEWAY_URL}/quotation/next`, {
        params: {
          quotation_id: number,
          direction: "next",
          creator: creator,
          email: Cookies.get("email", ""),
        },
      })
      .then((response) => {
        if (Object.keys(response.data).length == 0) {
          showNotification("warning", "Quotation doesn't exist");
        } else {
          setQuotationInfo(response.data);
        }
      })
      .catch((error) => {
        showNotification("error", "Error retrieving quotation");
      });
  };

  const goBack = async () => {
    axios
      .get(`${API_GATEWAY_URL}/quotation/previous`, {
        params: {
          quotation_id: number,
          direction: "previous",
          creator: creator,
          email: Cookies.get("email", ""),
        },
      })
      .then((response) => {
        setQuotationInfo(response.data);
        setCreateNew(false);
      })
      .catch((error) => {
        showNotification("error", "Error retrieving quotation");
      });
  };

  const clear = () => {
    window.location.reload();
  };

  return (
    <Box sx={{ padding: "1em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <Grid container spacing={2} className="quotation_header">
        <Grid item lg={6}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              style={{
                fontFamily: "AirbnbCereal-Bold",
                fontSize: "1.5rem",
                color: "#212b36",
              }}
            >
              Quotation
            </Typography>
            <Typography
              style={{
                fontFamily: "AirbnbCereal-Bold",
                fontSize: "1rem",
                color: "#212b36",
                padding: "0em 0em 0.5em 0em",
                color:
                  approvalStatus == "PENDING"
                    ? "gray"
                    : approvalStatus == "PENDING APPROVAL"
                    ? "orange"
                    : approvalStatus == "APPROVED"
                    ? "green"
                    : "red",
              }}
            >
              {approvalStatus} {approvalReason != "" && "|"}{" "}
              <span
                style={{
                  fontFamily: "AirbnbCereal-Book",
                  fontSize: "0.9rem",
                  color: "#212b36",
                }}
              >
                {approvalReason}
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} />
        <Grid item lg={2}>
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {Cookies.get("role", null) != "ADMIN" ? (
                <StyledBlueButton
                  onClick={() => setSubmitApproval(!submitApproval)}
                  startIcon={<Check style={{ fontSize: "14px" }} />}
                >
                  Submit for Approval
                </StyledBlueButton>
              ) : (
                <StyledBlueButton
                  onClick={() => setOpenApprove(!openApprove)}
                  startIcon={<Check style={{ fontSize: "14px" }} />}
                >
                  Approve/Reject
                </StyledBlueButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="button_layer">
        <Grid container item lg={6} spacing={1}>
          <Grid item>
            <IconButton
              onClick={goBack}
              sx={{
                padding: "5px 15px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "20px",
                borderColor: "#898B8D",
                color: "#898B8D",
                "&:hover": {
                  backgroundColor: "#898B8D",
                  color: "#fff",
                  transitionDuration: "0.6s",
                },
              }}
            >
              <ArrowBack sx={{ height: "15px", width: "15px" }} />
              <Typography
                sx={{
                  fontFamily: "AirbnbCereal-Medium",
                  fontSize: "0.5em",
                  paddingLeft: "7px",
                }}
              >
                BACK
              </Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={clear}
              sx={{
                padding: "5px 15px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "20px",
                borderColor: "#898B8D",
                color: "#898B8D",
                "&:hover": {
                  backgroundColor: "#898B8D",
                  color: "#fff",
                  transitionDuration: "0.6s",
                },
              }}
            >
              <RefreshIcon sx={{ fontSize: 15 }} />
              <Typography
                sx={{
                  fontFamily: "AirbnbCereal-Medium",
                  fontSize: "0.5em",
                  paddingLeft: "7px",
                }}
              >
                REFRESH
              </Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={goForward}
              sx={{
                padding: "5px 15px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "20px",
                borderColor: "#898B8D",
                color: "#898B8D",
                "&:hover": {
                  backgroundColor: "#898B8D",
                  color: "#fff",
                  transitionDuration: "0.6s",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "AirbnbCereal-Medium",
                  fontSize: "0.5em",
                  paddingRight: "7px",
                }}
              >
                NEXT
              </Typography>
              <ArrowForward sx={{ fontSize: 15 }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Box
            style={{
              color: "red",
              padding: "0.5em 0em",
              fontFamily: "AirbnbCereal-Medium",
              textAlign: "right",
              fontSize: "13px",
            }}
          >
            Last edited: {lastEditor} {lastEditDate}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item spacing={2} lg={12} sx={{ marginTop: "0.5em" }}>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <StyledBox>
              <StyledTextField
                fullWidth
                variant="outlined"
                value={number}
                label="Quotation Number"
                onChange={(e) => setNumber(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          performQuotationSearch();
                        }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                  },
                }}
                style={{ marginBottom: "1em" }}
              />
              <StyledTextField
                fullWidth
                variant="outlined"
                value={company}
                label="Company Name"
                onChange={(e) => setCompany(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                          performCompanySearch();
                        }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                  },
                }}
                style={{ marginBottom: "1em" }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <FormControl
                  sx={{
                    flex: 2,
                    paddingRight: "1em",
                  }}
                >
                  <InputLabel>Contact Person</InputLabel>
                  <StyledSelect
                    style={{
                      color: "#212b36",
                      fontFamily: "AirbnbCereal-Book",
                      fontSize: "1em",
                      transitionDuration: "0.6s",
                      padding: "0px 20px 0px 1px",
                      borderRadius: "5px",
                      "&:hover fieldset": {
                        borderColor: "#212b36",
                        transitionDuration: "0.6s",
                      },
                      "&:focus": {
                        borderColor: "#212b36",
                      },
                    }}
                    variant="outlined"
                    value={attention.contact_name || ""}
                    label="Contact Person"
                    onChange={handleAttentionChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    }}
                  >
                    {attentionOptions.map((item, index) => {
                      return (
                        <MenuItem
                          key={item.contact_name}
                          value={item.contact_name}
                          style={{
                            color: "#212b36",
                            fontFamily: "AirbnbCereal-Book",
                            fontSize: "1em",
                          }}
                        >
                          {item.contact_name}
                        </MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>
                <StyledTextField
                  label="Phone"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ flex: 1, paddingRight: "1em", alignSelf: "center" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
                <StyledTextField
                  label="Ext"
                  variant="outlined"
                  value={extension}
                  onChange={(e) => setExtension(e.target.value)}
                  style={{ flex: 1, paddingRight: "1em", alignSelf: "center" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
                <StyledTextField
                  label="Mobile"
                  variant="outlined"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={{ flex: 1, alignSelf: "center" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <StyledTextField
                  label="Reference"
                  variant="outlined"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  style={{ flex: 2 }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControl
                  style={{
                    flex: 2,
                  }}
                >
                  <InputLabel>Status</InputLabel>
                  <StyledSelect
                    style={{
                      color: "#212b36",
                      fontFamily: "AirbnbCereal-Book",
                      fontSize: "1em",
                      transitionDuration: "0.6s",
                      padding: "0px 20px 0px 1px",
                      borderRadius: "5px",
                      "&:hover fieldset": {
                        borderColor: "#212b36",
                        transitionDuration: "0.6s",
                      },
                      "&:focus": {
                        borderColor: "#212b36",
                      },
                    }}
                    variant="outlined"
                    value={status}
                    label="Status"
                    onChange={(e) => setStatus(e.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                    }}
                  >
                    {statusOptions.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={item}
                          style={{
                            color: "#212b36",
                            fontFamily: "AirbnbCereal-Book",
                            fontSize: "1em",
                          }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>
              </Box>
            </StyledBox>
          </Grid>
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <StyledBox>
              <StyledTextField
                fullWidth
                label="Date"
                variant="outlined"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ marginBottom: "1em" }}
                InputProps={{
                  sx: {
                    fontFamily: "AirbnbCereal-Book",
                  },
                }}
              />
              <StyledTextField
                fullWidth
                label="Account Manager"
                variant="outlined"
                value={accountManager}
                onChange={(e) =>
                  setAccountManager(e.target.value.toUpperCase())
                }
                style={{ marginBottom: "1em" }}
                InputProps={{
                  sx: {
                    fontFamily: "AirbnbCereal-Book",
                  },
                }}
              />
              <FormControl sx={{ flex: 1, width: "100%", marginBottom: "1em" }}>
                <InputLabel>Currency</InputLabel>
                <StyledSelect
                  style={{
                    color: "#212b36",
                    fontFamily: "AirbnbCereal-Book",
                    fontSize: "1em",
                    transitionDuration: "0.6s",
                    padding: "0px 20px 0px 1px",
                    borderRadius: "5px",
                    "&:hover fieldset": {
                      borderColor: "#212b36",
                      transitionDuration: "0.6s",
                    },
                    "&:focus": {
                      borderColor: "#212b36",
                    },
                  }}
                  variant="outlined"
                  value={currency}
                  label="Currency"
                  onChange={(e) => setCurrency(e.target.value)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }}
                >
                  {currencyOptions.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={item}
                        style={{
                          color: "#212b36",
                          fontFamily: "AirbnbCereal-Book",
                          fontSize: "1em",
                        }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </FormControl>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1em",
                }}
              >
                <StyledTextField
                  label="Shipment Terms"
                  variant="outlined"
                  value={shipment}
                  onChange={(e) => setShipment(e.target.value)}
                  sx={{ flex: 1, paddingRight: "1em" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
                <StyledTextField
                  label="Payment Terms"
                  variant="outlined"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                  sx={{ flex: 1 }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <StyledTextField
                  label="Quote Validity"
                  variant="outlined"
                  value={validity}
                  onChange={(e) => setValidity(e.target.value)}
                  sx={{ flex: 1, paddingRight: "1em" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
                <StyledTextField
                  label="Revision"
                  variant="outlined"
                  value={revision}
                  onChange={(e) => setRevision(e.target.value)}
                  sx={{ flex: 1, paddingRight: "1em" }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
                <StyledTextField
                  label="Remarks"
                  variant="outlined"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  sx={{ flex: 1 }}
                  InputProps={{
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                    },
                  }}
                />
              </Box>
            </StyledBox>
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "0.5em" }} xs={12} md={12} lg={12}>
          <BasicTable
            items={lineItems}
            populateItems={setLineItems}
            handleLineItemImport={handleLineItemImport}
            quotation_id={number}
            customer={company}
          />
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} lg={2}>
            {Cookies.get("role", null) !== "SALES" && (
              <StyledGreenButton
                onClick={ExportInternalUse}
                startIcon={
                  <FileDownloadOutlinedIcon style={{ fontSize: "14px" }} />
                }
              >
                Internal Use
              </StyledGreenButton>
            )}
          </Grid>
          <Grid item xs={12} md={1} lg={4}></Grid>
          <Grid item xs={12} md={2} lg={2} sx={{ paddingLeft: "1em" }}>
            {createNew ? (
              <StyledGrayButton
                onClick={CreateNewQuotation}
                startIcon={
                  <AddCircleOutlineOutlinedIcon style={{ fontSize: "14px" }} />
                }
              >
                Create new
              </StyledGrayButton>
            ) : (
              <StyledGrayButton
                onClick={SaveQuotation}
                startIcon={
                  <AddCircleOutlineOutlinedIcon style={{ fontSize: "14px" }} />
                }
              >
                Save
              </StyledGrayButton>
            )}
          </Grid>
          <Grid item xs={12} md={3} lg={2} sx={{ paddingLeft: "1em" }}>
            <StyledRedButton
              onClick={ExportPDF}
              startIcon={
                <PictureAsPdfOutlinedIcon style={{ fontSize: "14px" }} />
              }
            >
              Export PDF
            </StyledRedButton>
          </Grid>
          <Grid item xs={12} md={3} lg={2} sx={{ paddingLeft: "1em" }}>
            <StyledGreenButton
              onClick={ExportExcel}
              startIcon={
                <FileDownloadOutlinedIcon style={{ fontSize: "14px" }} />
              }
            >
              Export Excel
            </StyledGreenButton>
          </Grid>
        </Grid>
      </Grid>
      {/* Approve / Reject */}
      <ApproveQuotationModal
        open={openApprove}
        close={() => {
          setOpenApprove(false);
        }}
        confirm={confirmApprove}
        approvalReason={approvalReason}
        setApprovalReason={setApprovalReason}
      />
      {/* Submit for approval */}
      <ApprovalSubmissionModal
        open={submitApproval}
        close={() => setSubmitApproval(!submitApproval)}
        confirm={confirmSubmitApproval}
      />
    </Box>
  );
};

export default CreateQuotation;
