import React, { useState, useContext } from "react";
import {
  TextField,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Box,
  IconButton,
  Tooltip,
  Modal,
  TablePagination,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import {
  StyledGrayButton,
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons";
import { StyledTableCell } from "../components/StyledTableComponents";
import { StyledSelect } from "../components/StyledSelect";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { NotificationContext } from "../components/NotificationProvider";
import { styles } from "../styles";
import useClasses from "../useClasses";

const QuotationAdmin = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [quote, setQuote] = useState("");
  const [results, setResults] = useState([]);
  const [searchQuotation, setSearchQuotation] = useState("");
  const [clear, setClear] = useState(false);
  const [salesPerson, setSalesPerson] = useState("");
  const [salesPeople, setSalesPeople] = useState([]);
  const [search, setSearch] = useState(false);

  const [open, setOpen] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [approvalReason, setApprovalReason] = useState("");

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const reloadPendingApprovals = (page) => {
    const params = {
      page: page,
      page_size: 10,
      ...(searchQuotation ? { quotation_id: searchQuotation } : {}),
      ...(salesPerson ? { sales_person: salesPerson } : {}),
    };
    axios
      .get(`${API_GATEWAY_URL}/quotation/pending-approval`, {
        params,
      })
      .then((response) => {
        let items = [];
        setTotalCount(response.data.total);
        if (response.data.quotations.length > 0) {
          for (let lineItem of response.data.quotations) {
            items.push({
              quotation_id: lineItem.quotation_id,
              company_name: lineItem.company_name,
              last_update: lineItem.last_update,
              currency: lineItem.currency,
              type: lineItem.type,
              status: lineItem.status,
              approval_status: lineItem.approval_status,
              sales_person: lineItem.sales_person,
            });
          }
        } else {
          showNotification("warning", "No Pending Quotations Number.");
        }
        setResults(items);
        setPage(page - 1);
      })
      .catch((error) => {
        showNotification("error", error);
      });
  };

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    reloadPendingApprovals(1);
    getSalesPersonList();
  }, [salesPerson, clear]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (search) {
        reloadPendingApprovals(1);
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchQuotation]);

  // open quotation info
  const quotationSearch = async (number) => {
    await axios
      .get(`${API_GATEWAY_URL}/quotation/search`, {
        params: {
          quotation_id: number,
          creator: Cookies.get("employee", null),
          email: Cookies.get("email", ""),
        },
      })
      .then(function (response) {
        setLineItems(response.data.line_items);
      })
      .catch(function (error) {
        showNotification("error", error);
      });
  };

  const viewDetails = (quote) => {
    setQuote(quote.quotation_id);
    quotationSearch(quote.quotation_id);
    setOpen(true);
  };

  function getSalesPersonList() {
    axios
      .get(`${API_GATEWAY_URL}/account/get-sales-people`)
      .then((response) => {
        let results = [];
        for (let i = 0; i < response.data.options.length; i++) {
          results.push(response.data.options[i]["name"]);
        }
        setSalesPeople(results);
      })
      .catch((error) => {
        showNotification("error", error);
      })
      .finally(() => {});
  }

  const handleClose = () => {
    setOpen(false);
    setLineItems([]);
  };

  const handleApprove = (status) => {
    // call approve api
    axios
      .post(`${API_GATEWAY_URL}/quotation/approve`, {
        quotation_id: quote,
        status: status,
        reason: approvalReason,
      })
      .then((resp) => {
        // successful approval
        showNotification("success", resp.data);
        // update approval status
        axios
          .post(`${API_GATEWAY_URL}/quotation/approve/notify`, {
            quotation_id: quote,
            status: status,
            reason: approvalReason,
          })
          .then((resp) => {
            console.log("Notified successfully");
          })
          .catch((error) => {
            console.log("Failed to notify");
          });
        handleClose();
        reloadPendingApprovals(1);
      })
      .catch((error) => {
        // unsucessful approval, display error
        showNotification("error", error.response.data);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // make call for next page
    reloadPendingApprovals(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    const inputValue = event.target.value.trim();
    setSearch(true);
    setSearchQuotation(inputValue);
  };

  const handleSelectedSalesPerson = (event) => {
    const inputValue = event.target.value;
    setSalesPerson(inputValue);
    setSearch(true);
  };

  const handleClearClick = () => {
    setClear((prev) => !prev);
    setSearchQuotation("");
    setSalesPerson("");
  };

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
        width: "100%",
      }}
    >
      <StyledHeader>Pending Approval</StyledHeader>
      <Grid container xs={12} lg={12} spacing={2} alignItems="center">
        <Grid item xs={12} lg={2}>
          <FormControl fullWidth>
            <InputLabel>Sales Person</InputLabel>
            <StyledSelect
              value={salesPerson || ""}
              label="Sales Person"
              onChange={(e) => {
                console.log("saleperson", e);
                handleSelectedSalesPerson(e);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                getContentAnchorEl: null,
              }}
            >
              {salesPeople.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            fullWidth
            variant="outlined"
            label="Quotation No."
            value={searchQuotation}
            onChange={handleSearchInput}
          />
        </Grid>
        <Grid item xs={2} lg={1}>
          <StyledRedButton
            onClick={() => {
              handleClearClick();
            }}
          >
            Clear
          </StyledRedButton>
        </Grid>
      </Grid>

      <Box className={classes.container}>
        <Box
          sx={{
            margin: "2em 0em",
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <TableContainer
            sx={{
              boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
              borderRadius: "8px",
              overflowX: "auto",
              width: "70%",
            }}
          >
            <Table
              sx={{
                backgroundColor: "#f3f6f8",
                color: "white",
                "&::before, &::after": {
                  borderColor: "red",
                },
                "& .MuiTableCell-sizeSmall": {
                  padding: "20px",
                },
                overflow: "hidden",
              }}
            >
              <TableHead>
                <TableRow sx={{ border: "none", whiteSpace: "nowrap" }}>
                  <TableCell
                    style={{ paddingLeft: "30px", width: "50px" }}
                  ></TableCell>
                  <TableCell>Quotation No.</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell style={{ paddingRight: "30px" }}>
                    Last Update
                  </TableCell>
                  <TableCell>Sales Person</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "#fff",
                        border: "#f3f6f8 dotted 2px",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{ padding: "0px 0px 0px 10px" }}
                      >
                        <Tooltip title="View Details">
                          <IconButton
                            onClick={() => {
                              viewDetails(result);
                            }}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#e3e9ef",
                                color: "#2f3945",
                                transition: "0.3s",
                              },
                            }}
                          >
                            <UnfoldMoreOutlinedIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.quotation_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.company_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.type}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ paddingRight: "30px" }}
                      >
                        {result.last_update}
                      </StyledTableCell>
                      <StyledTableCell>{result.sales_person}</StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.modal_container}>
          <StyledHeader style={{ paddingTop: "0px" }}>{quote}</StyledHeader>
          <TableContainer
            sx={{
              boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            <Table className={classes.table}>
              <TableHead>
                <TableRow sx={{ border: "none", whiteSpace: "nowrap" }}>
                  <TableCell style={{ paddingLeft: "30px" }}>Line</TableCell>
                  <TableCell>CPN</TableCell>
                  <TableCell>MPN</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Usage</TableCell>
                  <TableCell>SPQ</TableCell>
                  <TableCell>Packaging</TableCell>
                  <TableCell>MOQ</TableCell>
                  <TableCell>C.Price</TableCell>
                  <TableCell>S.Price</TableCell>
                  <TableCell>Profit</TableCell>
                  <TableCell>LT</TableCell>
                  <TableCell>CXL</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell style={{ paddingRight: "30px" }}>
                    Build Site
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((result, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "#fff",
                        border: "#f3f6f8 dotted 2px",
                      }}
                    >
                      <StyledTableCell
                        style={{ paddingLeft: "30px" }}
                        align="left"
                      >
                        {result.line_number}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.cpn}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.mpn}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.manufacturer}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.annual_usage}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.spq}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.packaging}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.moq}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.customer_price}
                        {!isNaN(result.customer_price) &&
                        !isNaN(result.supplier_price) &&
                        parseFloat(result.customer_price) <
                          0.945 * parseFloat(result.supplier_price)
                          ? " ⚠️"
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.supplier_price}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.customer_price &&
                        result.supplier_price &&
                        result.moq
                          ? (
                              (parseFloat(result.customer_price) -
                                parseFloat(result.supplier_price)) *
                              parseFloat(result.moq)
                            ).toFixed(2)
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.lead_time}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.cancellation}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.target_price}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.updates}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {result.project}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ paddingRight: "30px" }}
                        align="left"
                      >
                        {result.build_site}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TextField
            label="Approve/Reject Reason"
            variant="outlined"
            value={approvalReason}
            onChange={(e) => setApprovalReason(e.target.value)}
            style={{ flex: 1, width: "100%", marginTop: "2em" }}
            multiline
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "1em",
            }}
          >
            <StyledGreenButton
              onClick={() => handleApprove("APPROVED")}
              style={{ marginRight: "1em" }}
            >
              Approve
            </StyledGreenButton>
            <StyledRedButton
              onClick={() => handleApprove("REJECTED")}
              style={{ marginRight: "1em" }}
            >
              Reject
            </StyledRedButton>
            <StyledGrayButton onClick={handleClose}>Cancel</StyledGrayButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default QuotationAdmin;
