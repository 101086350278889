import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, CircularProgress, Backdrop, Modal } from "@mui/material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import { useNavigate } from "react-router-dom";
import SupplierNameTable from "../components/SupplierNameTable";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import _debounce from "lodash/debounce";
import {
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { CancelOutlined } from "@mui/icons-material";
import { NotificationContext } from "../components/NotificationProvider";

const CreateSupplier = () => {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { showNotification } = useContext(NotificationContext);

  /* if we need pagination 
    const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

    function handleDebounceFn(supplier) {
        setOpen(true);
        axios
            .get(`${API_GATEWAY_URL}/supplier`)
            .then((response) => {
                setResults(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setOpen(false);
            });
    }
    */

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    axios
      .get(`${API_GATEWAY_URL}/supplier`)
      .then((response) => {
        setResults(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  // for future if we need pagination
  const filterSearch = (mpn, supplier) => {
    //setsupplier(supplier.toUpperCase());
    //debounceFn(mpn, supplier, 1);
  };

  const handleChangePage = (event, newPage) => {
    //setPage(newPage);
    //handleDebounceFn(mpn, supplier, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
  };

  const updateSearchResults = (index, updatedRow) => {
    setResults((prevResults) =>
      prevResults.map((row, i) => (i === index ? updatedRow : row))
    );
  };

  const deleteSearchResult = (index) => {
    setResults((prevResults) => prevResults.filter((_, i) => i !== index));
  };

  const createSupplier = async () => {
    await axios
      .post(`${API_GATEWAY_URL}/supplier`, {
        data: {
          supplier_name: supplier,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          showNotification("success", "Add Supplier successful");
          setSupplier("");
          setOpenSupplier(false);
          setRefresh(!refresh);
        } else {
          showNotification("error", `Error: ${response.status}`);
        }
      })
      .catch(function (error) {
        showNotification("error", "Error processing request, please try again");
      });
  };

  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>Supplier Name List</StyledHeader>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Modal
          open={openSupplier}
          onClose={() => {
            setOpenSupplier(false);
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "87vh",
              borderRadius: "8px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "auto",
              width: "30%",
              padding: "50px 20px 50px 20px",
              backgroundColor: "#fff",
              border: "none",
              boxShadow: 24,
              "&:focus": {
                border: "none",
              },
            }}
          >
            <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
              Add Supplier
            </StyledHeader>
            <StyledTextField
              freeSolo
              inputValue={supplier}
              onChange={(e) => setSupplier(e.target.value)}
              sx={{
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
                marginTop: "1.5em",
              }}
              label="Supplier name."
            />
            <Grid
              container
              lg={12}
              spacing={2}
              sx={{
                marginTop: "1em",
              }}
            >
              <Grid lg={6} item>
                <StyledGreenButton
                  onClick={createSupplier}
                  startIcon={
                    <AddBoxOutlinedIcon style={{ fontSize: "14px" }} />
                  }
                >
                  Add Supplier
                </StyledGreenButton>
              </Grid>
              <Grid lg={6} item>
                <StyledRedButton
                  onClick={() => {
                    setOpenSupplier(false);
                  }}
                  startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
                >
                  Close
                </StyledRedButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Box sx={{ marginLeft: "0" }}>
          <StyledGreenButton
            onClick={() => {
              setOpenSupplier(true);
            }}
          >
            Add Supplier
          </StyledGreenButton>
        </Box>
      </Box>
      <SupplierNameTable
        searchResults={results}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onUpdateSearchResults={updateSearchResults}
        onDeleteSearchResults={deleteSearchResult}
      />
      <Backdrop open={open} sx={{ color: "#fff", zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default CreateSupplier;
