import React from "react";
import { NotificationProvider } from "./components/NotificationProvider";
import AppContent from "./components/AppContent";

function App() {
  return (
    <NotificationProvider>
      <AppContent />
    </NotificationProvider>
  );
}

export default App;
