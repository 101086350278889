import React from "react";
import Notification from "./Notification";
import { useNotification } from "../hooks/useNotification";

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const notification = useNotification();

  return (
    <NotificationContext.Provider value={notification}>
      {children}
      <Notification
        open={notification.open}
        close={notification.hideNotification}
        message={notification.message}
        severity={notification.severity}
      />
    </NotificationContext.Provider>
  );
};
