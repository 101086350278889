import * as React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import {
  Backdrop,
  Box,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import { styles } from "../../styles";
import { API_GATEWAY_URL, getCurrentFormattedTime } from "../../utils";
import useClasses from "../../useClasses";

import InputSpacer from "../InputSpace";
import { StyledTextField } from "../StyledTextField";
import { StyledHeader } from "../StyledHeader";
import {
  StyledGrayButton,
  StyledGreenButton,
  StyledOrangeButton,
} from "../StyledButtons";
import { NotificationContext } from "../NotificationProvider";

const LineItemFormModal = (props) => {
  const classes = useClasses(styles);
  const { showNotification } = useContext(NotificationContext);
  const {
    modal,
    closeModal,
    newItem,
    addLineItem,
    updateLineItem,
    type,
    customer,
  } = props;

  const [cpn, setCpn] = useState("");
  const [supplier, setSupplier] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([
    "OPTION A",
    "OPTION B",
  ]);
  const [mpn, setMpn] = useState("");
  const [qty, setQty] = useState("");
  const [description, setDescription] = useState("");

  const [moq, setMoq] = useState("");
  const [customerPrice, setCustomerPrice] = useState(0);
  const [spq, setSpq] = useState(0);
  const [packaging, setPackaging] = useState("");
  const [leadTime, setLeadTime] = useState(0);
  const [cancellation, setCancellation] = useState(0);
  const [priceLastUpdateTime, setPriceLastUpdateTime] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [buildSite, setBuildSite] = useState("");
  const [project, setProject] = useState("");
  const [price, setPrice] = useState("");

  const [update, setUpdate] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");

  const [annualUsage, setAnnualUsage] = useState("");

  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_GATEWAY_URL}/supplier`)
      .then((response) => {
        let options = response.data.map((item) => {
          if (typeof item === "string") {
            return item.toUpperCase();
          }
          return item; // Return the item as-is if it's not a string
        });
        setSupplierOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (modal === true && type === "update") {
      setSupplier(newItem.manufacturer);
      setMpn(newItem.mpn);
      setAnnualUsage(newItem.annual_usage);
      setCpn(newItem.cpn);
      setMoq(newItem.moq);
      setCustomerPrice(newItem.customer_price);
      setSpq(newItem.spq);
      setPackaging(newItem.packaging);
      setLeadTime(newItem.lead_time);
      setCancellation(newItem.cancellation);
      setBuildSite(newItem.build_site);
      setProject(newItem.project);
      setPrice(newItem.target_price);
      setUpdate(newItem.updates);
      setLastUpdate(newItem.last_update);
      setDescription(newItem.description);

      let lineItem = {
        moq: newItem.moq,
        customer_price: newItem.customer_price,
        supplier_price: newItem.supplier_price,
        lead_time: newItem.lead_time,
        cancellation: newItem.cancellation,
      };
      let newItemList = [...lineItems, lineItem];
      setLineItems(newItemList);
    }
  }, [modal]);

  const handleAdd = () => {
    if (supplier === "" || mpn === "") {
      showNotification("warning", "Ensure all fields are filled");
      return;
    }

    let list = [];
    for (let item of lineItems) {
      list.push({
        // common properties
        manufacturer: supplier,
        mpn: mpn,
        annual_usage: annualUsage,
        cpn: cpn,
        description: description,
        spq: spq,
        packaging: packaging,
        build_site: buildSite,
        project: project,
        target_price: price,
        updates: update,
        last_update: lastUpdate,

        // individual properties
        moq: item.moq ? item.moq : "",
        customer_price: item.customer_price ? item.customer_price : "",
        supplier_price: item.supplier_price ? item.supplier_price : "",
        lead_time: item.lead_time ? item.lead_time : "",
        cancellation: item.cancellation ? item.cancellation : "",
      });
    }

    setSupplier("");
    setMpn("");
    setQty("");
    setCpn("");
    setDescription("");
    setSpq("");
    setPackaging("");
    setBuildSite("");
    setProject("");
    setPrice("");
    setUpdate("");
    setLastUpdate("");
    setLineItems([]);

    if (type == "create") {
      addLineItem(list);
    } else {
      updateLineItem(list);
    }
  };

  const performPriceListSearch = () => {
    // perform call to backend to search for price
    axios
      .get(`${API_GATEWAY_URL}/price-list`, {
        params: {
          supplier: supplier,
          mpn: mpn,
          qty: Number(qty),
          customer: customer,
        },
      })
      .then((response) => {
        if (Object.keys(response.data).length === 0) {
          showNotification(
            "error",
            "There was an error retrieving prices, try again later!"
          );
          return;
        }
        if (response.data.moq > qty) {
          showNotification("info", `Minimum MOQ is ${response.data.moq}`);
        }
        setSupplier(response.data.supplier);
        setMpn(response.data.mpn);
        setMoq(response.data.moq);
        setCustomerPrice(response.data.customer_price);
        setSpq(response.data.spq);
        setPackaging(response.data.packaging);
        setLeadTime(response.data.lead_time);
        setCancellation(response.data.cancellation);
        setPriceLastUpdateTime(response.data.last_updated);
        setExpiryDate(response.data.expiry_date);

        // items
        // mpn, unit price, supplier price, lead time, cancellation
        let lineItem = {
          moq: response.data.moq,
          customer_price: response.data.customer_price,
          supplier_price: response.data.supplier_price,
          lead_time: response.data.lead_time,
          cancellation: response.data.cancellation,
        };
        let newItemList = [...lineItems, lineItem];
        setLineItems(newItemList);
      })
      .catch((error) => {
        showNotification(
          "error",
          "There was an error retrieving prices, try again later!"
        );
      });
  };

  const reset = () => {
    setSupplier("");
    setMpn("");
    setQty("");
    setCpn("");
    setDescription("");
    setMoq("");
    setCustomerPrice("");
    setSpq("");
    setPackaging("");
    setLeadTime("");
    setPriceLastUpdateTime("");
    setExpiryDate("");
    setCancellation("");
    setBuildSite("");
    setProject("");
    setPrice("");
    setUpdate("");
    setLastUpdate("");
    setLineItems([]);
    closeModal();
  };

  const addRow = () => {
    let lineItem = {
      moq: "",
      customer_price: "",
      supplier_price: "",
      lead_time: "",
      cancellation: "",
    };
    let newItemList = [...lineItems, lineItem];
    setLineItems(newItemList);
  };

  return (
    <Modal
      open={modal}
      onClose={reset}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Box className={classes.modal_container}>
        <StyledHeader style={{ paddingTop: "0px" }}>Line Item</StyledHeader>
        <Box className={classes.modal_form}>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1em" }}>
            <Box sx={{ display: "flex", flex: 1 }}>
              <FormControl style={{ justifyContent: "flex-end" }} fullWidth>
                <InputLabel>MANUFACTURER</InputLabel>
                <Select
                  variant="outlined"
                  autoWidth
                  value={supplier}
                  label="MANUFACTURER"
                  onChange={(e) => setSupplier(e.target.value.toUpperCase())}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {supplierOptions.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <InputSpacer />
            <StyledTextField
              label="MPN"
              variant="outlined"
              value={mpn}
              onChange={(e) => setMpn(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="MOQ SEARCH"
              variant="outlined"
              value={qty}
              onChange={(e) => setQty(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledOrangeButton
                variant="outlined"
                className={classes.lineItemSearchButton}
                onClick={performPriceListSearch}
                style={{ border: "none" }}
              >
                Retrieve
              </StyledOrangeButton>
            </Box>
          </Box>
          <Box>
            <Typography
              style={{
                color: "orange",
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "0.9em",
                marginTop: "5px",
              }}
            >
              {priceLastUpdateTime != ""
                ? `Price Effective Date: ${priceLastUpdateTime}`
                : ""}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                color: "red",
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "0.9em",
                marginTop: "0px",
              }}
            >
              {expiryDate != "" ? `Price Expiry Date: ${expiryDate}` : ""}
            </Typography>
          </Box>
          <Divider style={{ margin: "10px 0px 20px 0px", height: "8px" }} />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1em",
            }}
          >
            <StyledTextField
              label="CPN"
              variant="outlined"
              value={cpn}
              onChange={(e) => setCpn(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="MANUFACTURER"
              variant="outlined"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="MPN"
              variant="outlined"
              value={mpn}
              onChange={(e) => setMpn(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1em",
            }}
          >
            <StyledTextField
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
              minRows={2}
              maxRows={2}
              multiline
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1em",
            }}
          >
            <StyledTextField
              label="SPQ"
              variant="outlined"
              value={spq}
              onChange={(e) => setSpq(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="PACKAGING"
              variant="outlined"
              value={packaging}
              onChange={(e) => setPackaging(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="BUILD SITE"
              variant="outlined"
              value={buildSite}
              onChange={(e) => setBuildSite(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="PROJECT"
              variant="outlined"
              value={project}
              onChange={(e) => setProject(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="TARGET PRICE"
              variant="outlined"
              value={price}
              onChange={(e) => setPrice(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
            <InputSpacer />
            <StyledTextField
              label="ANNUAL USAGE"
              variant="outlined"
              value={annualUsage}
              onChange={(e) => setAnnualUsage(e.target.value.toUpperCase())}
              style={{ flex: 1 }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1em",
            }}
          >
            <StyledTextField
              label="Follow-up Updates"
              variant="outlined"
              value={update}
              onChange={(e) => {
                setUpdate(e.target.value.toUpperCase());
                setLastUpdate(getCurrentFormattedTime());
              }}
              style={{ flex: 5 }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f6fbfd",
                },
              }}
            />
            <InputSpacer />
            <Box
              style={{
                flex: 1,
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "0.9em",
                textAlign: "right",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              {lastUpdate}
            </Box>
          </Box>
        </Box>
        {(type == "create" || type == "update") && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: "1em",
            }}
          >
            <StyledGreenButton
              variant="contained"
              onClick={addRow}
              //disabled={type != "create"}
              style={{ boxShadow: "none" }}
            >
              Add Row
            </StyledGreenButton>
          </Box>
        )}
        <Box style={{ display: "flex", alignItems: "center" }}>
          <TableContainer style={{ width: "70%", flex: 0.7 }}>
            <Table className={classes.table} size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="left">MOQ</TableCell>
                  <TableCell align="left">Customer Price</TableCell>
                  {["ADMIN", "MANAGER"].includes(Cookies.get("role", null)) && (
                    <TableCell align="left">Supplier Price</TableCell>
                  )}
                  <TableCell align="left">Lead Time</TableCell>
                  <TableCell align="left">Cancellation</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: "#fff" }}>
                {lineItems.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "#fff",
                        border: "#f3f6f8 dotted 2px",
                      }}
                    >
                      <TableCell align="left">
                        <StyledTextField
                          value={row.moq}
                          onChange={(e) => {
                            let updatedItems = [...lineItems];
                            updatedItems[index].moq = e.target.value;
                            setLineItems(updatedItems);
                          }}
                          InputProps={{
                            sx: {
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <StyledTextField
                          value={row.customer_price}
                          onChange={(e) => {
                            let updatedItems = [...lineItems];
                            updatedItems[index].customer_price = e.target.value;
                            setLineItems(updatedItems);
                          }}
                          InputProps={{
                            sx: {
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            },
                          }}
                        />
                      </TableCell>
                      {
                        // check by role
                        ["ADMIN", "MANAGER"].includes(
                          Cookies.get("role", null)
                        ) && (
                          <TableCell align="left">
                            <StyledTextField
                              value={row.supplier_price}
                              onChange={(e) => {
                                let updatedItems = [...lineItems];
                                updatedItems[index].supplier_price =
                                  e.target.value;
                                setLineItems(updatedItems);
                              }}
                              InputProps={{
                                sx: {
                                  fontFamily: "AirbnbCereal-Book",
                                  fontSize: "1em",
                                },
                              }}
                            />
                          </TableCell>
                        )
                      }
                      <TableCell align="left">
                        <StyledTextField
                          value={row.lead_time}
                          onChange={(e) => {
                            let updatedItems = [...lineItems];
                            updatedItems[index].lead_time = e.target.value;
                            setLineItems(updatedItems);
                          }}
                          InputProps={{
                            sx: {
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <StyledTextField
                          value={row.cancellation}
                          onChange={(e) => {
                            let updatedItems = [...lineItems];
                            updatedItems[index].cancellation = e.target.value;
                            setLineItems(updatedItems);
                          }}
                          InputProps={{
                            sx: {
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {(type == "create" || type == "update") && (
                          <IconButton
                            onClick={() => {
                              let newLineItems = lineItems.filter(
                                (el) => el !== row
                              );
                              setLineItems(newLineItems);
                            }}
                          >
                            <HighlightOffOutlinedIcon
                              style={{ color: "#f44336" }}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {lineItems.length > 0 && (
            <Box
              style={{
                paddingLeft: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "",
                flex: 0.3,
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: "AirbnbCereal-Book",
                  color: "red",
                  marginTop: "8px",
                  fontWeight: "bold",
                }}
              >
                Note: System generated lead time is manufacturer's production
                lead time. Please add at least 2-4 weeks.
              </Typography>
            </Box>
          )}
        </Box>
        <Box style={{ marginTop: "1em" }} />
        <Box className={classes.modal_button_container}>
          <StyledGrayButton onClick={reset} style={{ marginRight: "1em" }}>
            Cancel
          </StyledGrayButton>
          <StyledGreenButton onClick={handleAdd}>
            {type == "create" ? "Create" : "Update"} & Add
          </StyledGreenButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default LineItemFormModal;
