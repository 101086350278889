import React, { useEffect, useState, useContext } from "react";
import { TextField, Typography, FormControl, Box, Grid } from "@mui/material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { Autocomplete } from "@mui/lab";
import { StyledHeader } from "../components/StyledHeader";
import { StyledGreenButton } from "../components/StyledButtons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { NotificationContext } from "../components/NotificationProvider";

const ApprovedPriceList = () => {
  const { showNotification } = useContext(NotificationContext);

  let navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [display, setDisplay] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [options, setOptions] = useState([]);
  const [date, setDate] = useState(dayjs());

  const [error, setError] = useState("");

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    axios
      .get(`${API_GATEWAY_URL}/supplier`)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUpload = () => {
    // Ensure file is selected
    if (!selectedFile) {
      showNotification("error", "Please select a file to upload");
      return;
    }
    console.log("Selected file:", file);
    const reader = new FileReader();
    // Handle the file read completion
    reader.onload = (e) => {
      try {
        // Create Uint8Array from the file's binary data
        const data = new Uint8Array(e.target.result);
        // Read the workbook from the binary data
        const workbook = XLSX.read(data, { type: "array" });
        // Get the name of the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        // Convert the sheet data into JSON format
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log("Parsed Data:", jsonData);
        // Add validation: Check if the data is empty
        if (jsonData.length === 0) {
          showNotification("error", "The uploaded Excel  is empty");
          return;
        }
        setError("");
        console.log(jsonData);
        // Validation logic after data is parsed
        const validationErrors = [];
        // validate columns names
        const expectedColumns = [
          "PART NUMBER",
          "SPQ",
          "PACKAGING",
          "LEAD TIME",
          "EXPIRY DATE",
          "CURRENCY",
        ];
        const actualColumns = Object.keys(jsonData[0] || {});
        const missingColumns = expectedColumns.filter(
          (col) => !actualColumns.includes(col)
        );
        if (missingColumns.length > 0) {
          validationErrors.push(
            `Missing columns: ${missingColumns.join(", ")}`
          );
        }
        const validationRules = {
          SPQ: (value) => {
            const parsedValue = parseFloat(value);
            return Number.isInteger(parsedValue) && !isNaN(parsedValue); // Ensure it's a valid integer
          },
          EXPIRYDATE: (value) => {
            if (!value) return true; // Allow empty values
            return validateDate(value); // Validate only if a value is provided
          },
        };
        jsonData.forEach((row, index) => {
          Object.keys(validationRules).forEach((column) => {
            const value = row[column];
            if (value !== undefined && !validationRules[column](value)) {
              // If the validation fails, store the error message
              validationErrors.push(
                `Row ${
                  index + 1
                }: Invalid data in column "${column}" (${value}).`
              );
            }
          });
        });
        // After the loop, you can log the validation errors or show them to the user
        if (validationErrors.length > 0) {
          console.log("Validation Errors:", validationErrors);
          // Optionally, set the errors in state or handle accordingly
          setError(validationErrors); // Example: show errors in a single message
          showNotification(
            "error",
            "Please fix the validation errors before uploading."
          );
          return;
        }
        console.log("All data is valid. Proceeding to upload...");
        setError("");
        // upload direct to aws s3
        AWS.config.update({
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        });
        const upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: "wiselink-sg",
            Key:
              "price_list/" +
              display.toUpperCase() +
              "_" +
              date.format("YYYY-MM-DD") +
              ".xlsx",
            Body: file,
          },
        });
        const promise = upload.promise();
        promise
          .then((data) => {
            showNotification(
              "success",
              "Price list successfully uploaded. Prices are being stored as we speak, sit tight!"
            );
          })
          .catch((err) => {
            showNotification(
              "error",
              "Error processing request: " + err.message
            );
          });
      } catch (err) {
        showNotification("error", "Error parsing the Excel file.");
        console.error("Error reading the file:", err);
      }
    };
    // Handle any errors that occur during the file reading
    reader.onerror = () => {
      showNotification("error", "Error reading the file.");
      console.error("FileReader error:", reader.error);
    };
    // Read the file as an ArrayBuffer (binary data)
    reader.readAsArrayBuffer(file);
  };

  const validateDate = (value) => {
    // Regular expression to match the date format DD/MM/YY
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{2})$/;
    // Check if the date matches the format
    const match = value.match(datePattern);
    if (!match) {
      return false; // Invalid format
    }
    const day = parseInt(match[1], 10); // Day (DD)
    const month = parseInt(match[2], 10); // Month (MM)
    const year = parseInt(match[3], 10); // Year (YY)
    // Create a Date object using the parsed values
    const date = new Date(`20${year}-${month}-${day}`);
    // Check if the date is valid
    if (
      date.getDate() !== day ||
      date.getMonth() + 1 !== month ||
      date.getFullYear() !== 2000 + year
    ) {
      return false; // Invalid date
    }
    return true; // Valid date
  };

  // Function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Split to remove data URL prefix
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      let base64File = await fileToBase64(file);
      setSelectedFile(base64File);
      setFileName(file.name);
    }
  };

  const handleInputChange = (event, input) => {
    setDisplay(input);
  };

  const handleDownloadPriceListTemplate = () => {
    // download direct to aws s3
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: "wiselink-sg",
      Key: "price_list/approved_price_list_template.xlsx",
    };

    s3.getObject(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        const fileContent = data.Body; // S3 returns the content as a buffer
        const fileName = "approved_price_list_template.xlsx";
        const blob = new Blob([fileContent], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // Trigger the download
        link.click();
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          padding: "0em 1em",
          fontFamily: "AirbnbCereal-Medium",
        }}
      >
        <StyledHeader>Approved Price List</StyledHeader>
        <Typography
          sx={{
            fontFamily: "AirbnbCereal-Book",
            fontSize: "1rem",
            color: "#41464c",
          }}
        >
          {display !== null && display.length > 0
            ? "Company name will be: " + display
            : "File name will be used as company name"}
        </Typography>
        <Grid container sx={{ marginTop: "1em" }} xs={12} lg={4} spacing={2}>
          <Grid container item xs={12} lg={12}>
            <Grid item xs={12} lg={6}>
              <DatePicker
                fullWidth
                value={date}
                label="Effective Date"
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setDate(value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{ display: "flex", alignItems: "center", pl: 2 }}
            >
              <StyledGreenButton
                onClick={handleDownloadPriceListTemplate}
                sx={{ width: "100%", height: "100%", fontSize: "0.7em" }}
              >
                download template
              </StyledGreenButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo={false}
                options={options}
                onChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        fontFamily: "AirbnbCereal-Book",
                        fontSize: "0.9em",
                      },
                    }}
                    label="Price List"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <input type="file" onChange={handleFileChange} />
          </Grid>
          <Grid container item xs={12} lg={12}>
            <StyledGreenButton
              onClick={handleUpload}
              sx={{ fontSize: "0.8em" }}
            >
              Upload
            </StyledGreenButton>
          </Grid>
        </Grid>
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>
            <h4>Errors:</h4>
            <ul>
              {error.map((err, idx) => (
                <li key={idx}>{err}</li>
              ))}
            </ul>
          </div>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default ApprovedPriceList;
