import React, { useCallback, useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Box,
  TextField,
  TablePagination,
  Backdrop,
  CircularProgress,
  Grid,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { useEffect } from "react";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom/";
import * as XLSX from "xlsx";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import { StyledGreenButton } from "../components/StyledButtons";
import { StyledTableCell } from "../components/StyledTableComponents";
import _debounce from "lodash/debounce";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const styles = (theme) => ({
  // necessary for content to be below app bar
  header: {
    fontFamily: "AirbnbCereal-Bold",
    fontSize: "1.5rem",
    color: "#212b36",
    padding: "1em 0em 0.5em 0em",
  },
  text_field: {
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "10px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  text_font: {
    paddingLeft: "10px",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },

  // results - 2nd half
  resultContainer: {
    marginTop: "2em",
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  tableContainer: {
    boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
    borderRadius: "8px",
    width: "80%",
  },
  table: {
    backgroundColor: "#f3f6f8",
    color: "white",
    "&::before, &::after": {
      borderColor: "red",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "20px",
    },
    overflow: "hidden",
  },
  tableRowHeader: {
    border: "none",
    whiteSpace: "nowrap",
  },
  tableHeaderText: {
    color: "#637381",
    fontFamily: "AirbnbCereal-Medium",
    textAlign: "left",
    fontSize: "12px",
  },
  tableText: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    padding: "8px 10px",
    fontSize: "12px",
  },
  tableRow: {
    backgroundColor: "#fff",
    borderColor: "#f3f6f8",
    borderStyle: "dotted",
  },
  tableCellIconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "150px",
    justifyContent: "flex-end",
  },
  pdf: {
    color: "#212b36",
    borderRadius: "none",
  },
  excel: {
    color: "#01a76e",
    borderRadius: "none",
  },

  // pop over
  popoverContainer: {
    "& .MuiPopover-paper": {
      width: "150px", // Set the desired width
      borderRadius: "8px",
      boxShadow: "rgba(145, 158, 171, 0.24) 0px 0px 20px 0px",
    },
  },
  popoverButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px 0px 10px 15px",
    color: "#212b36",
  },
  popoverText: {
    marginLeft: "5px",
    textTransform: "capitalize",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },
  popoverBoxider: {
    border: "none",
    borderBottomStyle: "dashed",
    borderColor: "rgba(145, 158, 171, 0.2)",
    borderWidth: "0px 0px thin",
  },
});

const StockCheck = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [mpn, setMpn] = useState("");
  const [brand, setBrand] = useState("");

  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(mpn, brand, page) {
    setOpen(true);
    axios
      .get(`${API_GATEWAY_URL}/stock/list`, {
        params: {
          creator: Cookies.get("employee", null),
          role: Cookies.get("role", "SALES"),
          mpn: mpn ? mpn : "",
          brand: brand ? brand : "",
          page: page ? page : 1,
          page_size: 10,
        },
      })
      .then((response) => {
        let stock_list = [];
        let stocks = response.data.items;
        setTotalCount(response.data.total);
        stocks.sort((a, b) => {
          let nameA = a.brand.toUpperCase(); // ignore upper and lowercase
          let nameB = b.brand.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; // nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0; // names must be equal
        });
        for (let item of stocks) {
          stock_list.push({
            mpn: item.mpn,
            brand: item.brand,
            stock_spo: item.stock_spo,
            stock: item.stock,
            spo: item.spo,
            cpo: item.cpo,
            excess: item.excess,
            last_update: item.last_update,
          });
        }
        console.log(stock_list);
        setResults(stock_list);
        setSearchResults(stock_list);
        setPage(page - 1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setOpen(false);
      });
  }

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    debounceFn(null, null, 1);
  }, []);

  const exportExcel = () => {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();

    let line_items = [];
    for (let i = 0; i < searchResults.length; i++) {
      // format into correct row
      let line_item = results[i];
      let row = {
        MPN: line_item.mpn,
        Brand: line_item.brand,
        "Stock SPO": line_item.stock_spo,
        Stock: line_item.stock,
        SPO: line_item.spo,
        CPO: line_item.cpo,
        Excess: line_item.excess,
      };
      line_items.push(row);
    }

    const ws = XLSX.utils.json_to_sheet(line_items);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook
    XLSX.writeFile(wb, `export.xlsx`);
  };

  const filterSearch = (mpn, brand) => {
    setMpn(mpn.toUpperCase());
    setBrand(brand.toUpperCase());
    debounceFn(mpn, brand, 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // make call for next page
    handleDebounceFn(mpn, brand, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
      }}
    >
      <StyledHeader>Stock List</StyledHeader>
      <Grid container xs={12} lg={12} spacing={2}>
        <Grid item xs={12} lg={3}>
          <StyledTextField
            placeholder={"MPN"}
            value={mpn}
            fullWidth={true}
            onChange={(e) => filterSearch(e.target.value, brand)}
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <StyledTextField
            placeholder={"Brand"}
            value={brand}
            fullWidth={true}
            onChange={(e) => filterSearch(mpn, e.target.value)}
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        {/* <Grid item xs={12} lg={1}></Grid> */}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            fontFamily: "AirbnbCereal-Book",
            fontSize: "0.8em",
            textAlign: "right",
          }}
        >
          <b>Stock + SPO</b> = Total physical stock and backorders |{" "}
          <b>Stock</b> = Physical Stock in Singapore
          <div />
          <b>SPO</b> = Backorder with factory | <b>CPO</b> = Total customer
          order quantity
          <div />
          <b>Excess</b> = Excess stock available for sale
        </Grid>
      </Grid>
      <Box className={classes.resultContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell style={{ paddingLeft: "30px" }}>Mpn</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Stock + SPO</TableCell>
                <TableCell>Stock</TableCell>
                <TableCell>SPO</TableCell>
                <TableCell>CPO</TableCell>
                <TableCell>Excess</TableCell>
                <TableCell style={{ paddingRight: "30px" }}>
                  Last Update
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((result, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      border: "#f3f6f8 dotted 2px",
                    }}
                  >
                    <StyledTableCell
                      align="left"
                      style={{ paddingLeft: "30px" }}
                    >
                      {result.mpn}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.brand}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.stock_spo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.stock}
                    </StyledTableCell>
                    <StyledTableCell align="left">{result.spo}</StyledTableCell>
                    <StyledTableCell align="left">{result.cpo}</StyledTableCell>
                    <StyledTableCell align="left">
                      {result.excess}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ paddingRight: "30px" }}
                    >
                      {result.last_update}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} lg={9}></Grid>
        <Grid item xs={12} lg={3}>
          {Cookies.get("role", "SALES") == "ADMIN" && (
            <StyledGreenButton
              onClick={exportExcel}
              startIcon={
                <FileDownloadOutlinedIcon style={{ fontSize: "14px" }} />
              }
            >
              Export Excel
            </StyledGreenButton>
          )}
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default StockCheck;
