import { useState } from "react";

export const useNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const showNotification = (severity, message) => {
    setSeverity(severity);
    setMessage(message);
    setOpen(true);
  };

  const hideNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return {
    open,
    message,
    severity,
    showNotification,
    hideNotification,
  };
};
