import React, { useCallback, useEffect, useState, useContext } from "react";
import { StyledHeader } from "../components/StyledHeader";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "../components/StyledTableComponents";
import _debounce from "lodash/debounce";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  roles,
  validateSession,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { CancelOutlined, Delete, Edit, MoreVert } from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StaffForm from "../components/StaffForm";
import useStyles from "../styles";
import {
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import DeleteFormModal from "../components/modal/DeleteFormModal";
import { NotificationContext } from "../components/NotificationProvider";

import { styles } from "../styles";
import useClasses from "../useClasses";

const StaffManagement = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [staff, setStaff] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [editIndex, setEditIndex] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const moreOpen = Boolean(anchorEl);
  const moreId = moreOpen ? "simple-popover" : undefined;

  // new sales person
  const [openSalesPerson, setOpenSalesPerson] = useState(false);

  const [sales, setSales] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [visibility, setVisibility] = useState([]);

  function handleDebounceFn() {
    // setOpen(true);
    axios
      .get(`${API_GATEWAY_URL}/account/get-sales-people`)
      .then((response) => {
        setStaff(response.data.options);
        let results = [];
        for (let i = 0; i < response.data.options.length; i++) {
          results.push(response.data.options[i]["name"]);
        }
        setSales(results);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        // setOpen(false);
      });
  }

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    debounceFn(null, null, null, null, null, null, 1);
  }, []);

  const handleMore = (e, row, index) => {
    setAnchorEl(e.currentTarget);
    setSelectedStaff(row);
    setEditIndex(page * rowsPerPage + index);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const openEditModal = () => {
    setModal(true);
  };

  const save = (name, role, visibility, email, password) => {
    // update staff value
    axios
      .post(`${API_GATEWAY_URL}/account/update-sales-person`, {
        name: name,
        role: role,
        visibility: visibility,
        email: email,
        password: password,
      })
      .then((response) => {
        showNotification("success", "Updated!");
        handleDebounceFn();
      });
  };

  const closeForm = () => {
    setEmail("");
    setName("");
    setPassword("");
    setVisibility([]);
    setRole("");
    setOpenSalesPerson(false);
  };

  const createSalesPerson = () => {
    // create sales person
    axios
      .post(`${API_GATEWAY_URL}/account/create-account`, {
        name: name.toUpperCase(),
        email: email,
        password: password,
        role: role,
        visibility: visibility,
      })
      .then((response) => {
        if (response.data.status == "FAILED") {
          showNotification("error", response.data.message);
        } else {
          showNotification("success", response.data.message);
        }
        closeForm();
        handleDebounceFn();
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVisibility(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const deleteStaff = () => {};

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: "0.5em",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontFamily: "AirbnbCereal-Bold",
              fontSize: "1.5rem",
              color: "#212b36",
              padding: "0.5em 0em 0.5em 0em",
            }}
          >
            Staff
          </Typography>
        </Box>
        <Box sx={{ alignContent: "center", display: "flex" }}>
          <Box sx={{ alignContent: "center" }}>
            <StyledGreenButton
              onClick={() => setOpenSalesPerson(true)}
              startIcon={<PersonAddIcon style={{ fontSize: "14px" }} />}
            >
              Create
            </StyledGreenButton>
          </Box>
        </Box>
      </Box>

      <Box
        container
        sx={{
          margin: "2em 0em",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "50%",
          alignItems: "flex-start",
        }}
      >
        <TableContainer
          sx={{
            boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
            borderRadius: "8px",
            overflowX: "auto",
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell style={{ paddingLeft: "30px" }}>No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell style={{ paddingRight: "30px" }}>
                  Visibility
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staff.map((result, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      border: "#f3f6f8 dotted 2px",
                    }}
                  >
                    <StyledTableCell
                      style={{ paddingLeft: "30px" }}
                      align="left"
                    >
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.email}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.role}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.role == "ADMIN"
                        ? "All"
                        : result.role == "SALES"
                        ? "Personal"
                        : result.visibility.join(", ")}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ paddingRight: "30px" }}
                      align="right"
                    >
                      <div className={classes.table_cell_icon_wrapper}>
                        <IconButton
                          onClick={(e) => {
                            handleMore(e, result, index);
                          }}
                          aria-label="edit"
                          size="small"
                          className={classes.more_vert}
                        >
                          <MoreVert fontSize="inherit" />
                        </IconButton>
                        <Popover
                          id={moreId}
                          open={moreOpen}
                          anchorEl={anchorEl}
                          onClose={handleMoreClose}
                          className={classes.popover_container}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <div className={classes.popover}>
                            <Button
                              onClick={() => {
                                handleMoreClose();
                                openEditModal();
                              }}
                              variant="text"
                              className={classes.popover_button}
                              startIcon={<Edit />}
                              sx={{
                                justifyContent: "flex-start",
                                marginLeft: "1em",
                              }}
                            >
                              <Typography className={classes.popover_text}>
                                Edit
                              </Typography>
                            </Button>
                            <div className={classes.popover_divider} />
                            <Button
                              onClick={() => {
                                handleMoreClose();
                                setDeleteModal(true);
                              }}
                              style={{
                                color: "#ff5630",
                                justifyContent: "flex-start",
                                marginLeft: "1em",
                              }}
                              variant="text"
                              className={classes.popover_button}
                              startIcon={<Delete />}
                            >
                              <Typography className={classes.popover_text}>
                                Delete
                              </Typography>
                            </Button>
                          </div>
                        </Popover>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <StaffForm
        staff={selectedStaff}
        open={modal}
        close={() => setModal(false)}
        save={save}
      />
      <Modal
        open={openSalesPerson}
        onClose={() => {
          closeForm();
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <StyledHeader sx={{ marginBottom: "1em", padding: "0em" }}>
            Create Sales Person
          </StyledHeader>
          {/* name, email, password */}
          <StyledTextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ flex: 1, marginBottom: "1em" }}
            InputProps={{
              sx: {
                fontFamily: "AirbnbCereal-Book",
              },
            }}
          />
          <StyledTextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ flex: 1, marginBottom: "1em" }}
            InputProps={{
              sx: {
                fontFamily: "AirbnbCereal-Book",
              },
            }}
          />
          <StyledTextField
            label="Password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ flex: 1, marginBottom: "1em" }}
            InputProps={{
              sx: {
                fontFamily: "AirbnbCereal-Book",
              },
            }}
          />
          <FormControl fullWidth style={{ marginBottom: "1em" }}>
            <InputLabel>Role</InputLabel>
            <StyledSelect
              autoWidth
              value={role}
              label="Role"
              onChange={(e) => setRole(e.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                getContentAnchorEl: null,
              }}
            >
              {roles.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: "1em" }}>
            <InputLabel>Visibility</InputLabel>
            <StyledSelect
              disabled={role != "MANAGER"}
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={visibility}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
            >
              {sales.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </StyledSelect>
            {role != "MANAGER" && (
              <Typography
                sx={{
                  marginTop: "3px",
                  fontFamily: "AirbnbCereal-Book",
                  textAlign: "right",
                  fontSize: "14px",
                  color: "red",
                }}
              >
                Admins - full visibility / Sales - personal data
              </Typography>
            )}
          </FormControl>
          <Grid
            container
            lg={12}
            sx={{
              marginTop: "1em",
            }}
            columnSpacing={1}
          >
            <Grid lg={6} item>
              <StyledGreenButton
                onClick={createSalesPerson}
                startIcon={<PersonAddIcon style={{ fontSize: "14px" }} />}
              >
                Create
              </StyledGreenButton>
            </Grid>
            <Grid lg={6} item>
              <StyledRedButton
                onClick={() => {
                  closeForm();
                }}
                startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
              >
                Close
              </StyledRedButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <DeleteFormModal
        confirm={deleteStaff}
        cancel={() => {
          setDeleteModal(false);
        }}
        open={deleteModal}
        item={selectedStaff}
        name={selectedStaff.name}
      />
    </Box>
  );
};

export default StaffManagement;
