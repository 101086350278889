import React, { useEffect, useState, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { invalidateCredentials, validateSession } from "../utils";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { StyledHeader } from "../components/StyledHeader";
import { StyledGreenButton } from "../components/StyledButtons";
import { NotificationContext } from "../components/NotificationProvider";

const StockList = () => {
  let navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
  }, []);

  const handleUpload = () => {
    // create company
    if (!selectedFile) {
      showNotification("error", "Please select a file to upload");
      return;
    }

    // upload direct to aws s3
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });

    let extension = fileName.split(".")[1];

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: "wiselink-sg",
        Key: `stock_list/stock_list.${extension}`,
        Body: file,
      },
    });
    const promise = upload.promise();
    promise
      .then((data) => {
        showNotification(
          "success",
          "Stock list successfully uploaded. Prices are being stored as we speak, sit tight!"
        );
      })
      .catch((err) => {
        showNotification("error", "Error processing request: " + err.message);
      });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      let base64File = await fileToBase64(file);
      setSelectedFile(base64File);
      setFileName(file.name);
    }
  };

  // Function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Split to remove data URL prefix
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
      }}
    >
      <StyledHeader>Stock List</StyledHeader>
      <Grid container sx={{ marginTop: "1em" }} xs={12} lg={4} spacing={2}>
        <Grid container item xs={12} lg={12}>
          <input type="file" onChange={handleFileChange} />
        </Grid>
        <Grid container item xs={12} lg={12} sx={{ marginTop: "0.5em" }}>
          <StyledGreenButton onClick={handleUpload}>Upload</StyledGreenButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StockList;
