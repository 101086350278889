import React, { useState } from "react";
import {
  Button,
  Typography,
  TextField,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableContainer,
  Box,
  TablePagination,
  Backdrop,
  CircularProgress,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Popover,
} from "@mui/material";
import { FileCopy, GifBoxRounded } from "@mui/icons-material";
import { useEffect, useCallback } from "react";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import {
  StyledGreenButton,
  StyledDropDownButton,
} from "../components/StyledButtons";
import { StyledTableCell } from "../components/StyledTableComponents";
import _debounce from "lodash/debounce";
import { StyledSelect } from "../components/StyledSelect";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

const styles = (theme) => ({
  // necessary for content to be below app bar
  wrapper: {
    padding: "1em 2em",
    fontFamily: "AirbnbCereal-Medium",
  },
  header: {
    fontFamily: "AirbnbCereal-Bold",
    fontSize: "1.5rem",
    color: "#212b36",
    padding: "1em 0em 0.5em 0em",
  },
  container: {
    color: "white",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text_field: {
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "10px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  text_font: {
    paddingLeft: "10px",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },

  // results - 2nd half
  resultContainer: {
    margin: "2em 0em",
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  tableContainer: {
    width: "auto",
    minWidth: "400px",
    boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
    borderRadius: "8px",
  },
  table: {
    backgroundColor: "#f3f6f8",
    color: "white",
    "&::before, &::after": {
      borderColor: "red",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "20px",
    },
    overflow: "hidden",
  },
  tableRowHeader: {
    border: "none",
    whiteSpace: "nowrap",
  },
  tableHeaderText: {
    color: "#637381",
    fontFamily: "AirbnbCereal-Medium",
    padding: "8px 10px",
    textAlign: "left",
    fontSize: "12px",
  },
  tableText: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    padding: "8px 10px",
    fontSize: "12px",
  },
  tableRow: {
    backgroundColor: "#fff",
    borderColor: "#f3f6f8",
    borderStyle: "dotted",
  },
  tableCellIconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "150px",
    justifyContent: "flex-end",
  },
  pdf: {
    color: "#212b36",
    borderRadius: "none",
  },
  excel: {
    color: "#01a76e",
    borderRadius: "none",
  },

  // pop over
  popoverContainer: {
    "& .MuiPopover-paper": {
      width: "150px", // Set the desired width
      borderRadius: "8px",
      boxShadow: "rgba(145, 158, 171, 0.24) 0px 0px 20px 0px",
    },
  },
  popoverButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px 0px 10px 15px",
    color: "#212b36",
  },
  popoverText: {
    marginLeft: "5px",
    textTransform: "capitalize",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "13px",
  },
  popoverBoxider: {
    border: "none",
    borderBottomStyle: "dashed",
    borderColor: "rgba(145, 158, 171, 0.2)",
    borderWidth: "0px 0px thin",
  },
});

const GetQuotation = () => {
  const classes = useClasses(styles);
  let navigate = useNavigate();
  const [quote, setQuote] = useState("");
  const [company, setCompany] = useState("");
  const [cpn, setCpn] = useState("");
  const [mpn, setMpn] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [type, setType] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [salesPeople, setSalesPeople] = useState([]);
  const [exportAll, setExportAll] = useState("");

  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  const handleClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMoreAnchorEl(null);
  };

  const moreOpen = Boolean(moreAnchorEl);
  const moreId = moreOpen ? "simple-popover" : undefined;

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  function getSalesPersonList() {
    axios
      .get(`${API_GATEWAY_URL}/account/get-sales-people`)
      .then((response) => {
        let results = [];
        for (let i = 0; i < response.data.options.length; i++) {
          results.push(response.data.options[i]["name"]);
        }
        setSalesPeople(results);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {});
  }

  function handleDebounceFn(
    quotation_id,
    company,
    mpn,
    cpn,
    manufacturer,
    type,
    salesPerson,
    page
  ) {
    setOpen(true);
    return axios
      .get(`${API_GATEWAY_URL}/quotation/paginate`, {
        params: {
          creator: Cookies.get("employee"),
          email: Cookies.get("email"),
          quotation_id: quotation_id ? quotation_id : "",
          company: company ? company : "",
          mpn: mpn ? mpn : "",
          cpn: cpn ? cpn : "",
          manufacturer: manufacturer ? manufacturer : "",
          type: type ? type : "",
          sales_person: salesPerson ? salesPerson : "",
          page: page ? page : 1,
          page_size: 10,
        },
      })
      .then((response) => {
        let items = [];
        let line_items = response.data.line_items;
        setTotalCount(response.data.total);
        line_items.sort((a, b) => {
          if (a.quotation_id === b.quotation_id) {
            return a.line_number - b.line_number;
          }

          return (
            parseInt(b.quotation_id.split("EQ-")[1]) -
            parseInt(a.quotation_id.split("EQ-")[1])
          );
        });
        for (let lineItem of line_items) {
          let update = lineItem.last_update;
          if (update != "") {
            update = update.split(" ")[1].toUpperCase();
          }
          items.push({
            quotation_id: lineItem.quotation_id,
            line_no: lineItem.line_number,
            cpn: lineItem.cpn,
            mpn: lineItem.mpn,
            manufacturer: lineItem.manufacturer,
            usage: lineItem.annual_usage,
            project: lineItem.project,
            build_site: lineItem.build_site,
            spq: lineItem.spq,
            packaging: lineItem.packaging,
            moq: lineItem.moq,
            c_price: lineItem.customer_price,
            s_price: lineItem.supplier_price,
            lead_time: lineItem.lead_time,
            cancellation: lineItem.cancellation,
            target: lineItem.target_price,
            update: lineItem.updates,
            company: lineItem.company,
            last_update: update,
            description: lineItem.description,
            currency: lineItem.currency,
            type: lineItem.type,
            status: lineItem.status,
          });
        }
        setSearchResults(items);
        setResults(items);
        setPage(page - 1);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setOpen(false);
      });
  }

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    debounceFn(null, null, null, null, null, null, null, 1, false);
    if (Cookies.get("role", "SALES") == "ADMIN") getSalesPersonList();
  }, []);

  const exportExcel = (exportItems) => {
    exportItems = exportItems == null ? searchResults : exportItems;
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    let line_items = [];
    for (let i = 0; i < exportItems.length; i++) {
      // format into correct row
      let line_item = exportItems[i];
      let row = {
        "Quotation ID": line_item.quotation_id,
        Company: line_item.company,
        "Company Type": line_item.type,
        "Line No": line_item.line_no,
        "CPN ( 30max )": line_item.cpn,
        "MPN ( 30max )": line_item.mpn,
        "Description ( 40max )": line_item.description,
        "Brand ( 20max )": line_item.manufacturer,
        "Usage (10)": line_item.usage == null ? "" : line_item.usage.toString(),
        "Project ( 25max)": line_item.project,
        "Build Site (20max)": line_item.build_site,
        SPQ: line_item.spq == null ? "" : line_item.spq.toString(),
        MOQ: line_item.moq == null ? "" : line_item.moq.toString(),
        CPrice: line_item.c_price == null ? "" : line_item.c_price.toString(), // need to verify
        "Sup.Curr": line_item.currency,
        SPrice: line_item.s_price == null ? "" : line_item.s_price.toString(),
        "LT ( wks )": line_item.lead_time,
        "Cancel ( wks )": line_item.cancellation,
        "Target Price":
          line_item.target == null ? "" : line_item.target.toString(),
        Business: "",
        Packaging: line_item.packaging,
        "Follow-Up update ( 100max )": line_item.updates,
      };
      line_items.push(row);
    }

    const ws = XLSX.utils.json_to_sheet(line_items);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook
    XLSX.writeFile(wb, `export.xlsx`);
  };

  const filterSearch = (
    quote_number,
    company,
    cpn,
    mpn,
    manufacturer,
    type,
    salesPerson
  ) => {
    setQuote(quote_number);
    setCompany(company);
    setMpn(mpn);
    setCpn(cpn);
    setManufacturer(manufacturer);
    setType(type.toUpperCase());
    setSalesPerson(salesPerson);
    debounceFn(
      quote_number,
      company,
      mpn,
      cpn,
      manufacturer,
      type,
      salesPerson,
      1,
      exportAll
    );
  };

  const handleChangePage = (event, newPage) => {
    console.log("new page: ", newPage);
    setPage(newPage);
    // make call for next page
    handleDebounceFn(
      quote,
      company,
      cpn,
      mpn,
      manufacturer,
      type,
      salesPerson,
      newPage + 1
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportExcelAll = async () => {
    await axios
      .get(`${API_GATEWAY_URL}/quotation/paginate`, {
        params: {
          creator: Cookies.get("employee"),
          email: Cookies.get("email"),
          quotation_id: quote ? quote : "",
          company: company ? company : "",
          mpn: mpn ? mpn : "",
          cpn: cpn ? cpn : "",
          manufacturer: manufacturer ? manufacturer : "",
          type: type ? type : "",
          sales_person: salesPerson ? salesPerson : "",
          page: page ? page : 1,
          page_size: 10,
          export_all: "true",
        },
      })
      .then((response) => {
        let exportItems = [];
        let line_items = response.data.line_items;
        setTotalCount(response.data.total);
        line_items.sort((a, b) => {
          if (a.quotation_id === b.quotation_id) {
            return a.line_number - b.line_number;
          }

          return (
            parseInt(b.quotation_id.split("EQ-")[1]) -
            parseInt(a.quotation_id.split("EQ-")[1])
          );
        });
        for (let lineItem of line_items) {
          let update = lineItem.last_update;
          if (update != "") {
            update = update.split(" ")[1].toUpperCase();
          }
          exportItems.push({
            quotation_id: lineItem.quotation_id,
            line_no: lineItem.line_number,
            cpn: lineItem.cpn,
            mpn: lineItem.mpn,
            manufacturer: lineItem.manufacturer,
            usage: lineItem.annual_usage,
            project: lineItem.project,
            build_site: lineItem.build_site,
            spq: lineItem.spq,
            packaging: lineItem.packaging,
            moq: lineItem.moq,
            c_price: lineItem.customer_price,
            s_price: lineItem.supplier_price,
            lead_time: lineItem.lead_time,
            cancellation: lineItem.cancellation,
            target: lineItem.target_price,
            update: lineItem.updates,
            company: lineItem.company,
            last_update: update,
            description: lineItem.description,
            currency: lineItem.currency,
            type: lineItem.type,
            status: lineItem.status,
          });
        }
        exportExcel(exportItems);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        padding: "0em 1em",
        fontFamily: "AirbnbCereal-Medium",
      }}
    >
      <Grid container style={{ padding: "1em 0em 0.5em 0em" }} spacing={2}>
        <Grid item xs={6} lg={6}>
          <StyledHeader style={{ padding: 0 }}>Retrieve</StyledHeader>
        </Grid>
        <Grid item xs={6} lg={6}>
          {Cookies.get("role", "SALES") === "ADMIN" && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                color: "red",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <IconButton aria-describedby={moreId} onClick={handleClick}>
                <MoreHorizIcon sx={{ color: "#252b32" }} />
              </IconButton>
              <Popover
                id={moreId}
                open={moreOpen}
                anchorEl={moreAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ borderRadius: "8px" }}>
                  <Box sx={{ width: "100%" }}>
                    <StyledDropDownButton
                      startIcon={
                        <FilterListOutlinedIcon style={{ fontSize: "14px" }} />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        fontFamily: "AirbnbCereal-Book",
                        textTransform: "none",
                        letterSpacing: 0,
                        fontSize: "15px",
                      }}
                      onClick={() => exportExcel()}
                    >
                      export current page
                    </StyledDropDownButton>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <StyledDropDownButton
                      startIcon={
                        <PostAddOutlinedIcon style={{ fontSize: "14px" }} />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        fontFamily: "AirbnbCereal-Book",
                        textTransform: "none",
                        letterSpacing: 0,
                        fontSize: "15px",
                      }}
                      onClick={exportExcelAll}
                    >
                      export all records
                    </StyledDropDownButton>
                  </Box>
                </Box>
              </Popover>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Quote number"}
            defaultValue={quote}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                e.target.value,
                company,
                cpn,
                mpn,
                manufacturer,
                type,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Company"}
            defaultValue={company}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                quote,
                e.target.value,
                cpn,
                mpn,
                manufacturer,
                type,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Company Type"}
            defaultValue={type}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                quote,
                company,
                cpn,
                mpn,
                manufacturer,
                e.target.value,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"CPN"}
            defaultValue={cpn}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                quote,
                company,
                e.target.value,
                mpn,
                manufacturer,
                type,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"MPN"}
            defaultValue={mpn}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                quote,
                company,
                cpn,
                e.target.value,
                manufacturer,
                type,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <StyledTextField
            placeholder={"Manufacturer"}
            defaultValue={mpn}
            fullWidth={true}
            variant="outlined"
            onChange={(e) =>
              filterSearch(
                quote,
                company,
                cpn,
                mpn,
                e.target.value,
                type,
                salesPerson
              )
            }
            InputProps={{
              sx: {
                color: "#212b36",
                fontFamily: "AirbnbCereal-Book",
                fontSize: "1em",
              },
            }}
          />
        </Grid>
      </Grid>
      {Cookies.get("role", "SALES") == "ADMIN" && (
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0.5em",
            width: "100%",
          }}
        >
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Sales Person</InputLabel>
              <StyledSelect
                value={salesPerson}
                label="Sales Person"
                onChange={(e) =>
                  filterSearch(
                    quote,
                    company,
                    cpn,
                    mpn,
                    manufacturer,
                    type,
                    e.target.value
                  )
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {salesPeople.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Box
        container
        sx={{
          margin: "3em 0em 2em 0em",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "50%",
          alignItems: "flex-start",
        }}
      >
        <TableContainer
          sx={{
            boxShadow: "0px 0px 30px 2px rgba(212, 212, 212, 0.3)",
            borderRadius: "8px",
            overflowX: "auto",
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowHeader}>
                <TableCell style={{ paddingLeft: "30px" }}>
                  Quotation No.
                </TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Line</TableCell>
                <TableCell>CPN</TableCell>
                <TableCell>MPN</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Usage</TableCell>
                <TableCell>SPQ</TableCell>
                <TableCell>Packaging</TableCell>
                <TableCell>MOQ</TableCell>
                <TableCell>C.Price</TableCell>
                <TableCell>S.Price</TableCell>
                <TableCell>LT</TableCell>
                <TableCell>CXL</TableCell>
                <TableCell>Target</TableCell>
                {(Cookies.get("role", null) === "ADMIN" ||
                  Cookies.get("role", null) === "MANAGER") && (
                  <>
                    <TableCell
                      className={classes.table_cell_header}
                      align="left"
                    >
                      Profit
                    </TableCell>
                    <TableCell
                      className={classes.table_cell_header}
                      align="left"
                    >
                      Markup %
                    </TableCell>
                  </>
                )}
                <TableCell>Update</TableCell>
                <TableCell>Project</TableCell>
                <TableCell>Build Site</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell style={{ paddingRight: "30px" }}>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((result, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      border: "#f3f6f8 dotted 2px",
                    }}
                  >
                    <StyledTableCell
                      style={{ paddingLeft: "30px" }}
                      align="left"
                    >
                      <Link
                        to="/quotation/create/"
                        onClick={() => {
                          // Store data in sessionStorage
                          sessionStorage.setItem(
                            "reportQuotationId",
                            result.quotation_id
                          );
                          sessionStorage.setItem("report", true);
                        }}
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {result.quotation_id}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        wordWrap: "break-word",
                        whiteSpace:
                          result.company.length > 35 ? "normal" : "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {result.company}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.line_no}
                    </StyledTableCell>
                    <StyledTableCell align="left">{result.cpn}</StyledTableCell>
                    <StyledTableCell align="left">{result.mpn}</StyledTableCell>
                    <StyledTableCell align="left">
                      {result.manufacturer}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.usage}
                    </StyledTableCell>
                    <StyledTableCell align="left">{result.spq}</StyledTableCell>
                    <StyledTableCell align="left">
                      {result.packaging}
                    </StyledTableCell>
                    <StyledTableCell align="left">{result.moq}</StyledTableCell>
                    <StyledTableCell align="left">
                      {result.c_price}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.s_price}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.lead_time}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.cancellation}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.target}
                    </StyledTableCell>
                    {(Cookies.get("role", null) == "ADMIN" ||
                      Cookies.get("role", null) == "Manager") && (
                      <>
                        <StyledTableCell
                          className={classes.table_cell}
                          align="left"
                          style={{ color: "red" }}
                        >
                          {result.c_price && result.s_price && result.moq
                            ? (
                                (parseFloat(result.c_price) -
                                  parseFloat(result.s_price)) *
                                parseFloat(result.moq)
                              ).toFixed(2)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.table_cell}
                          align="left"
                          style={{ color: "red" }}
                        >
                          {result.c_price && result.s_price
                            ? (
                                ((parseFloat(result.c_price) -
                                  parseFloat(result.s_price)) /
                                  parseFloat(result.s_price)) *
                                100
                              ).toFixed(0) + "%"
                            : "-"}
                        </StyledTableCell>
                      </>
                    )}

                    <StyledTableCell align="left">
                      {result.update}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.project}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.build_site}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.last_update}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {result.status}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ paddingRight: "30px" }}
                      align="left"
                    >
                      {result.type}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default GetQuotation;
