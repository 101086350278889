import React, { useEffect, useState, useContext } from "react";
import { Typography, styled, Box, Grid } from "@mui/material";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { StyledHeader } from "../components/StyledHeader";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import { DeleteQuotationModal } from "../components/modal/DeleteQuotationModal";
import { NotificationContext } from "../components/NotificationProvider";

const StyledBox = styled(Box)`
    // width: 200px;
    height: 60px;
    border-color: #cdd2d6;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #f3f6f8;
    transition-duration: 0.2s;
    :hover {
      background-color: #e0e0e0;
      transition-duration: 0.2s;
      cursor: pointer;
    },
  `;

const ModuleText = styled(Typography)`
  font-family: AirbnbCereal-Book;
  font-size: 0.8em;
  color: #29333e;
  margin-left: 1em;
`;

const Admin = () => {
  let navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);

  const [qid, setQid] = useState("");
  const [qidOptions, setQidOptions] = useState([]);
  const [selectedQid, setSelectedQid] = useState("");

  const [openQuotation, setOpenQuotation] = useState(false);

  const [role, setRole] = useState(Cookies.get("role", "MANAGER"));
  const [employee, setEmployee] = useState(Cookies.get("employee", ""));

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();

    axios
      .get(`${API_GATEWAY_URL}/quotation`, {
        params: {
          creator: employee,
          role: role,
        },
      })
      .then((response) => {
        response.data.sort((a, b) => {
          if (a.quotation_id === b.quotation_id) {
            return a.line_number - b.line_number;
          }
          return (
            parseInt(b.quotation_id.split("EQ-")[1]) -
            parseInt(a.quotation_id.split("EQ-")[1])
          );
        });

        const seen = new Set();
        const filtered_data = response.data.filter((obj) => {
          const duplicate = seen.has(obj["quotation_id"]);
          seen.add(obj["quotation_id"]);
          return !duplicate;
        });
        setQidOptions(filtered_data.map((item) => item.quotation_id));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleQuotationInputChange = (event, input) => {
    const value = input.toUpperCase();
    setQid(value);
    if (selectedQid != value && qidOptions.includes(value)) {
      setSelectedQid(value);
    }
  };

  const deleteQuotation = async () => {
    try {
      // Delete quotation
      await axios.delete(`${API_GATEWAY_URL}/quotation`, {
        data: {
          qid: qid.toUpperCase(),
        },
      });

      // Refresh quotation list
      const response = await axios.get(`${API_GATEWAY_URL}/quotation`, {
        params: {
          creator: employee,
          role: role,
        },
      });

      // Reset form state
      setQid("");
      setSelectedQid("");

      // Sort and deduplicate quotations
      const sortedData = response.data.sort((a, b) => {
        if (a.quotation_id === b.quotation_id) {
          return a.line_number - b.line_number;
        }
        return (
          parseInt(b.quotation_id.split("EQ-")[1]) -
          parseInt(a.quotation_id.split("EQ-")[1])
        );
      });

      const uniqueQuotations = [
        ...new Set(sortedData.map((item) => item.quotation_id)),
      ];
      setQidOptions(uniqueQuotations);

      showNotification(
        "success",
        "Successfully Deleted Quotation & Related line items"
      );
    } catch (error) {
      console.error(error);
      showNotification("error", "Error processing request, please try again");
    }
  };

  const MENU_ITEMS = [
    {
      title: "Pending Approvals",
      icon: AddchartOutlinedIcon,
      path: "/quotation-admin",
      access: "ALL",
    },
    {
      title: "Supplier",
      icon: ReceiptLongOutlinedIcon,
      path: "/supplier-admin",
      access: "ALL",
    },
    {
      title: "Stock List",
      icon: InventoryOutlinedIcon,
      path: "/stock-list",
      access: "ALL",
    },
    {
      title: "Company",
      icon: CorporateFareOutlinedIcon,
      path: "/company-admin",
      access: "ADMIN",
    },
    {
      title: "Delete Quotation",
      icon: RequestQuoteOutlinedIcon,
      onClick: (setOpenQuotation) => setOpenQuotation(true),
      access: "ADMIN",
    },
    {
      title: "Staff Management",
      icon: PersonSearchOutlinedIcon,
      path: "/staff-management",
      access: "ADMIN",
    },
  ];

  return (
    <Box sx={{ padding: "0em 1em", fontFamily: "AirbnbCereal-Medium" }}>
      <StyledHeader>
        <AdminPanelSettingsOutlinedIcon /> Admin
      </StyledHeader>
      <Grid container spacing={2}>
        {MENU_ITEMS.map(
          (item, index) =>
            (item.access === "ALL" || role === item.access) && (
              <Grid item lg={2} key={index}>
                <StyledBox
                  onClick={() => {
                    item.path
                      ? navigate(item.path)
                      : item.onClick(setOpenQuotation);
                  }}
                >
                  <item.icon fontSize="small" sx={{ color: "#29333e" }} />
                  <ModuleText>{item.title}</ModuleText>
                </StyledBox>
              </Grid>
            )
        )}
      </Grid>
      <DeleteQuotationModal
        openQuotation={openQuotation}
        setOpenQuotation={setOpenQuotation}
        qidOptions={qidOptions}
        qid={qid}
        setQid={setQid}
        setSelectedQid={setSelectedQid}
        deleteQuotation={deleteQuotation}
        handleQuotationInputChange={handleQuotationInputChange}
      />
    </Box>
  );
};

export default Admin;
