import React, { useContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Sidebar from "../components/Sidebar.js";
import Drawer from "@mui/material/Drawer";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import CreateQuotation from "../views/CreateQuotation.js";
import GetQuotation from "../views/GetQuotation.js";
import Contact from "../views/Contact.js";
import Company from "../views/Company.js";
import PriceList from "../views/PriceList.js";
import Login from "../views/Login.js";
import Instruction from "../views/Instruction.js";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  CancelOutlined,
  ChevronLeft,
  ChevronRight,
  Menu,
  SaveOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Toolbar,
} from "@mui/material";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/logo_slim.png"; // Adjust the path to your image file
import Admin from "../views/Admin.js";
import GetContact from "../views/GetContacts.js";
import MuiAppBar from "@mui/material/AppBar";
import StockCheck from "../views/StockCheck.js";
import QuotationAdmin from "../views/QuotationAdmin.js";
import { StyledHeader } from "../components/StyledHeader.js";
import {
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons.js";
import { API_GATEWAY_URL, invalidateCredentials } from "../utils.js";
import axios from "axios";
import { StyledTextField } from "../components/StyledTextField.js";
import StockList from "../views/StockList.js";
import StaffManagement from "../views/StaffManagement.js";
import SupplierPriceList from "../views/SupplierPriceList.js";
import CreateSupplierPriceList from "../views/CreateSupplierPriceList.js";
import CompanyAdmin from "../views/CompanyAdmin.js";
import SupplierAdmin from "../views/SupplierAdmin.js";
import CreateSupplier from "../views/CreateSupplier.js";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ApprovedPriceList from "../views/ApprovedPriceList.js";
import { NotificationContext } from "../components/NotificationProvider";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    // flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    width: "100%",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 0,
    }),
    // Responsive adjustments
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1), // Reduced padding on mobile
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        width: "100%", // Override to prevent width issues with open drawer on mobile
      }),
    },
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#fff",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Default props to be applied to all TextFields
          fontSize: "1em",
          fontFamily: "AirbnbCereal-Book",
          transitionDuration: "0.6s",
          borderRadius: "5px",
          backgroundColor: "#fff",
          "&:hover fieldset": {
            borderColor: "#212b36",
            transitionDuration: "0.6s",
          },
          "&:focus": {
            borderColor: "#212b36",
          },
          variant: "outlined",
          InputProps: {
            // For example, apply a style to all input elements
            style: {
              color: "#212b36",
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
            },
          },
          InputLabelProps: {
            // For example, apply a style to all input elements
            style: {
              color: "#212b36",
              fontFamily: "AirbnbCereal-Book",
              fontSize: "1em",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Book",
          fontSize: "1em",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Medium",
          fontSize: "0.95em",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#212b36",
          fontFamily: "AirbnbCereal-Medium",
          fontSize: "0.95em",
          padding: "12px 10px",
          whiteSpace: "nowrap",
        },
      },
    },
  },
});

const AppContent = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { showNotification } = useContext(NotificationContext);
  const [backdrop, setBackdrop] = useState(false);

  const handleOpenAccountModal = () => {
    setEmail(Cookies.get("email", ""));
    setPassword(Cookies.get("password", ""));
    setOpenAccountModal(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const updatePassword = () => {
    if (password.replace(" ", "").length != password.length) {
      showNotification(
        "warning",
        "Ensure there is no whitespace in your password"
      );
      return;
    }
    setBackdrop(true);
    axios
      .post(`${API_GATEWAY_URL}/account/change-password`, {
        email,
        password,
      })
      .then((res) => {
        if (res.status == 200) {
          showNotification("success", res.data);
        } else {
          showNotification("error", res.data);
        }
      })
      .catch((error) => {
        showNotification("error", "Error updating password, try again later.");
      })
      .finally(() => {
        setBackdrop(false);
      });
    Cookies.set("password", password);
  };

  const handleCloseBackdrop = () => {
    setBackdrop(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
        <CssBaseline />
        {useLocation().pathname != "/" && (
          <AppBar open={open}>
            <Toolbar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                    sx={{
                      mr: 2,
                      ...(open && { display: "none" }),
                      color: "#686868",
                    }}
                  >
                    <Menu />
                  </IconButton>
                  <img src={Logo} alt="Description" width={"150px"} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleOpenAccountModal}
                    startIcon={
                      <AccountCircleOutlinedIcon
                        sx={{ height: "15px", width: "15px" }}
                      />
                    }
                    sx={{
                      fontFamily: "AirbnbCereal-Medium",
                      color: "#2f3945",
                      borderColor: "#2f3945",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      fontSize: "0.7em",
                      "&:hover": {
                        backgroundColor: "#2f3945",
                        color: "#fff",
                      },
                      marginRight: "1em",
                    }}
                  >
                    Account
                  </Button>
                  <Button
                    onClick={() => {
                      invalidateCredentials();
                      navigate("/");
                    }}
                    sx={{
                      fontFamily: "AirbnbCereal-Medium",
                      color: "#dc831c",
                      borderColor: "#dc831c",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "5px",
                      padding: "5px 10px",
                      fontSize: "0.7em",
                      "&:hover": {
                        backgroundColor: "#dc831c",
                        color: "#fff",
                      },
                    }}
                    startIcon={
                      <LogoutOutlinedIcon
                        sx={{ height: "15px", width: "15px" }}
                      />
                    }
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#f3f6f8",
            },
            width: drawerWidth,
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Tooltip title="Instruction">
              <HelpOutlineOutlinedIcon
                onClick={() => navigate("/instruction/")}
                sx={{
                  marginRight: "145px",
                  cursor: "pointer",
                  fontSize: "20px",
                  "&:hover fieldset": {
                    borderColor: "#212b36",
                    transitionDuration: "0.6s",
                  },
                  "&:focus": {
                    borderColor: "#212b36",
                  },
                }}
              />
            </Tooltip>
            <Tooltip title="Minimize">
              <IconButton onClick={() => setOpen(false)}>
                {theme.direction === "ltr" ? (
                  <ChevronLeft style={{ color: "#686868" }} />
                ) : (
                  <ChevronRight />
                )}
              </IconButton>
            </Tooltip>
          </DrawerHeader>
          <Divider />
          <Sidebar />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/quotation-admin" element={<QuotationAdmin />} />
            <Route path="/staff-management" element={<StaffManagement />} />
            <Route exact path="/company" element={<Company />} />
            <Route exact path="/contact-person" element={<Contact />} />
            <Route exact path="/contact-person/get" element={<GetContact />} />
            <Route path="/price-list" element={<PriceList />} />
            <Route path="/stock-list" element={<StockList />} />
            <Route path="/stock-check" element={<StockCheck />} />
            <Route path="/quotation/create" element={<CreateQuotation />} />
            <Route path="/quotation/get" element={<GetQuotation />} />
            <Route path="/instruction/" element={<Instruction />} />
            <Route
              path="//supplier/price-list/"
              element={<SupplierPriceList />}
            />
            <Route
              path="/supplier/price-list/add/"
              element={<CreateSupplierPriceList />}
            />
            <Route path="/company-admin/" element={<CompanyAdmin />} />
            <Route path="/supplier-admin/" element={<SupplierAdmin />} />
            <Route path="/supplier/add/" element={<CreateSupplier />} />
            <Route
              path="/approved-price-list"
              element={<ApprovedPriceList />}
            />
          </Routes>
        </Main>
      </Box>
      <Modal open={openAccountModal} onClose={() => setOpenAccountModal(false)}>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "87vh",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "50%",
            padding: "50px 20px 50px 20px",
            backgroundColor: "#fff",
            border: "none",
            boxShadow: 24,
            "&:focus": {
              border: "none",
            },
          }}
        >
          <Box>
            <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
              Account
            </StyledHeader>
            <StyledTextField
              disabled
              label="Email"
              variant="outlined"
              value={email}
              style={{ flex: 1, width: "100%", marginTop: "1em" }}
            />
            <StyledTextField
              label="Password"
              variant="outlined"
              value={password}
              // type="password"
              onChange={handlePasswordChange}
              style={{ flex: 1, width: "100%", marginTop: "2em" }}
            />
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <StyledGreenButton
                  onClick={updatePassword}
                  style={{ marginTop: "2em" }}
                  startIcon={<SaveOutlined style={{ fontSize: "14px" }} />}
                >
                  Update Password
                </StyledGreenButton>
              </Grid>
              <Grid item lg={6}>
                <StyledRedButton
                  onClick={() => {
                    setOpenAccountModal(false);
                  }}
                  style={{ marginTop: "2em" }}
                  startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
                >
                  Close
                </StyledRedButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 1400 }}
        open={backdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default AppContent;
