import { Modal, Box, Backdrop } from "@mui/material";
import { styles } from "../../styles";
import useClasses from "../../useClasses";
import { StyledGreenButton, StyledRedButton } from "../StyledButtons";
import { StyledHeader } from "../StyledHeader";

export const ApprovalSubmissionModal = (props) => {
  const classes = useClasses(styles);
  const { open, close, confirm } = props;
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          className: classes.backdrop,
        },
      }}
    >
      <Box className={classes.edit_container}>
        <StyledHeader style={{ paddingTop: "0", fontSize: "1.2em" }}>
          Confirm Submission
        </StyledHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "2em",
          }}
        >
          <StyledGreenButton
            onClick={() => confirm()}
            style={{ marginRight: "1em" }}
          >
            Submit
          </StyledGreenButton>
          <StyledRedButton onClick={close}>Cancel</StyledRedButton>
        </Box>
      </Box>
    </Modal>
  );
};
