import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  styled,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  invalidateCredentials,
  validateSession,
} from "../utils";
import { useNavigate } from "react-router-dom";
import useClasses from "../useClasses";
import { styles } from "../styles";
import { StyledTextField } from "../components/StyledTextField";
import { StyledSelect } from "../components/StyledSelect";
import { StyledTableCell } from "../components/StyledTableComponents";
import {
  StyledGrayButton,
  StyledGreenButton,
  StyledRedButton,
} from "../components/StyledButtons";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NotificationContext } from "../components/NotificationProvider";

const StyledBox = styled(Box)(
  ({ theme }) => `
        border-radius: 10px;
        padding: 2em 1em;
        box-shadow: 0px 0px 30px 2px rgba(212, 212, 212, 0.3);
    `
);

const CreateSupplierPriceList = () => {
  let navigate = useNavigate();
  const classes = useClasses(styles);
  const { showNotification } = useContext(NotificationContext);

  const [mpn, setMpn] = useState("");
  const [packaging, setPackaging] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [cancellation, setCancellation] = useState("");
  const [expiryDate, setExpiryDate] = useState(dayjs().format("DD/MM/YY"));
  const [rows, setRows] = useState([{ moq: "", unit_price: "" }]);
  const [newRow, setNewRow] = useState({ moq: "", unit_price: "" });
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [options, setOptions] = useState([]);
  const [currency, setCurrency] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([
    "USD",
    "CNY",
    "MYR",
    "THB",
  ]);
  const [spq, setSpq] = useState("");

  const clearInputs = () => {
    setMpn("");
    setSelectedSupplier("");
    setCurrency("");
    setSpq("");
    setPackaging("");
    setLeadTime("");
    setCancellation("");
    setExpiryDate("");
    resetRows();
  };

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();
    axios
      .get(`${API_GATEWAY_URL}/supplier`)
      .then((response) => {
        let options = response.data.map((item) => {
          if (typeof item === "string") {
            return item.toUpperCase();
          }
          return item; // Return the item as-is if it's not a string
        });
        setOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const resetRows = () => {
    const clearedRows = rows.map((row) => {
      return {
        moq: "",
        unit_price: "",
      };
    });
    setRows(clearedRows);
  };

  // Function to handle edit changes in the table rows
  const handleEditChange = (e, rowIndex, column) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][column] = e.target.value;
    setRows(updatedRows);
  };

  // Function to handle adding a new row
  const handleAddRow = () => {
    setRows([...rows, newRow]);
    setNewRow({ moq: "", unit_price: "" });
  };

  // Function to handle adding a new row
  const handleDeleteRow = () => {
    setRows((prevRows) => prevRows.slice(0, -1));
  };

  const CreateNewPriceList = async () => {
    // Check if all required fields are filled
    const isPriceListValid = rows.every((item) => item.moq && item.unit_price); // Ensure all price list items have values
    if (
      !mpn ||
      !selectedSupplier ||
      !currency ||
      !packaging ||
      !leadTime ||
      !cancellation ||
      !expiryDate ||
      !isPriceListValid
    ) {
      showNotification("warning", "Please complete all required fields.");
      return;
    }
    await axios
      .post(`${API_GATEWAY_URL}/supplier/price-list/add-price`, {
        mpn: mpn,
        supplier: selectedSupplier,
        currency: currency,
        spq: spq,
        packaging: packaging,
        lead_time: leadTime,
        cancellation: cancellation,
        expiry_date: expiryDate,
        price_list: rows,
      })
      .then(async function (response) {
        if (response.status == 200) {
          showNotification("success", "Upload price list successful");
          clearInputs();
        } else {
          showNotification("error", `Error: ${response.status}`);
        }
      })
      .catch(function (error) {
        showNotification("error", `Error, try again`);
        return false;
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ padding: "1em 1em", fontFamily: "AirbnbCereal-Medium" }}>
        <Grid lg={12} spacing={2} container className="quotation_header">
          <Grid lg={6} item>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontFamily: "AirbnbCereal-Bold",
                  fontSize: "1.5rem",
                  color: "#212b36",
                }}
              >
                Add New Suppplier Price List
              </Typography>
            </Box>
          </Grid>
          <Grid lg={4} item />
        </Grid>
        <Grid container spacing={2} lg={12}>
          <Grid item container spacing={2} lg={6} sx={{ marginTop: "0.5em" }}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <StyledBox>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={mpn}
                  label="MPN"
                  onChange={(e) => setMpn(e.target.value)}
                  style={{ marginBottom: "1em" }}
                />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormControl
                    style={{
                      flex: 2,
                    }}
                  >
                    <InputLabel>Supplier</InputLabel>
                    <StyledSelect
                      style={{
                        color: "#212b36",
                        fontFamily: "AirbnbCereal-Book",
                        fontSize: "1em",
                        transitionDuration: "0.6s",
                        padding: "0px 20px 0px 1px",
                        borderRadius: "5px",
                        "&:hover fieldset": {
                          borderColor: "#212b36",
                          transitionDuration: "0.6s",
                        },
                        "&:focus": {
                          borderColor: "#212b36",
                        },
                        marginBottom: "1em",
                      }}
                      variant="outlined"
                      value={selectedSupplier}
                      label="Status"
                      onChange={(e) => setSelectedSupplier(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {options.map((item, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={item}
                            style={{
                              color: "#212b36",
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FormControl
                    style={{
                      flex: 2,
                    }}
                  >
                    <InputLabel>Currency</InputLabel>
                    <StyledSelect
                      style={{
                        color: "#212b36",
                        fontFamily: "AirbnbCereal-Book",
                        fontSize: "1em",
                        transitionDuration: "0.6s",
                        padding: "0px 20px 0px 1px",
                        borderRadius: "5px",
                        "&:hover fieldset": {
                          borderColor: "#212b36",
                          transitionDuration: "0.6s",
                        },
                        "&:focus": {
                          borderColor: "#212b36",
                        },
                        marginBottom: "1em",
                      }}
                      variant="outlined"
                      value={currency}
                      label="Status"
                      onChange={(e) => setCurrency(e.target.value)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {currencyOptions.map((item, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={item}
                            style={{
                              color: "#212b36",
                              fontFamily: "AirbnbCereal-Book",
                              fontSize: "1em",
                            }}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={spq}
                  label="SPQ"
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9]/g, "");
                    setSpq(newValue);
                  }}
                  style={{ marginBottom: "1em" }}
                />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={packaging}
                  label="Packaging"
                  onChange={(e) => {
                    // Ensure the value is a string (letters and optionally numbers or symbols like '-')
                    const newValue = e.target.value.replace(
                      /[^A-Za-z\s\-]/g,
                      ""
                    ); // only allow letters, numbers, spaces, and hyphens
                    setPackaging(newValue);
                  }}
                  style={{ marginBottom: "1em" }}
                />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={leadTime}
                  label="Lead Time (Weeks)"
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9\-]/g, "");
                    setLeadTime(newValue);
                  }}
                  style={{ marginBottom: "1em" }}
                />
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  value={cancellation}
                  label="Cancellation (Weeks)"
                  onChange={(e) => {
                    setCancellation(e.target.value);
                  }}
                  style={{ marginBottom: "1em" }}
                />
                <DatePicker
                  fullWidth
                  value={dayjs(expiryDate, "DD/MM/YY")} // Ensure it's a valid dayjs object
                  label="Expiry Date"
                  format="DD/MM/YYYY"
                  inputFormat="DD/MM/YY" // Specify the format for the input
                  onChange={(value) => {
                    setExpiryDate(value.format("DD/MM/YY"));
                  }}
                  sx={{ marginBottom: "0em" }} // Bottom margin here
                />
              </StyledBox>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid item lg={12}>
              <TableContainer
                className={classes.table_container}
                component={Paper}
                sx={{
                  marginBottom: "1em",
                  marginTop: "2em",
                  width: "auto",
                }}
              >
                <Table className={classes.table} size="medium">
                  <TableHead>
                    <TableRow className={classes.table_row_header}>
                      <TableCell
                        className={classes.table_cell_header}
                        align="center"
                      >
                        MOQ
                      </TableCell>
                      <TableCell
                        className={classes.table_cell_header}
                        align="center"
                      >
                        Unit Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: "#fff",
                          border: "#f3f6f8 dotted 2px",
                        }}
                      >
                        <StyledTableCell>
                          <StyledTextField
                            value={row.moq}
                            onChange={(e) => handleEditChange(e, index, "moq")}
                            variant="outlined"
                            fullWidth
                            type="number"
                            inputProps={{ step: "1" }}
                            onInput={(e) => {
                              // Allow only integers
                              e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              ); // This removes any non-digit characters
                            }}
                          />
                        </StyledTableCell>
                        <TableCell>
                          <StyledTextField
                            value={row.unit_price}
                            onChange={(e) =>
                              handleEditChange(e, index, "unit_price")
                            }
                            variant="outlined"
                            fullWidth
                            type="number"
                            inputProps={{ step: "0.0001" }}
                            onInput={(e) => {
                              let value = e.target.value;
                              // Allow only numbers with up to 4 decimal places
                              if (
                                value &&
                                !/^(\d+(\.\d{0,4})?)?$/.test(value)
                              ) {
                                // Replace any value that doesn't match the pattern
                                e.target.value = value.slice(0, -1);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container item xs={12} lg={12} spacing={2}>
              <Grid item xs={6} lg={6}>
                <StyledGreenButton onClick={handleAddRow}>
                  Add row
                </StyledGreenButton>
              </Grid>
              <Grid item xs={6} lg={6}>
                <StyledRedButton onClick={handleDeleteRow}>
                  Delete row
                </StyledRedButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item xs={12} md={2} lg={2}>
              <StyledGrayButton
                onClick={CreateNewPriceList}
                startIcon={<Save style={{ fontSize: "14px" }} />}
              >
                Create new
              </StyledGrayButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateSupplierPriceList;
