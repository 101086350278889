import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import axios from "axios";
import {
  API_GATEWAY_URL,
  getToday,
  invalidateCredentials,
  validateSession,
} from "../utils";
import Notification from "../components/Notification";
import { Autocomplete } from "@mui/lab";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useClasses from "../useClasses";
import { StyledHeader } from "../components/StyledHeader";
import { StyledTextField } from "../components/StyledTextField";
import {
  StyledRedButton,
  StyledGreenButton,
} from "../components/StyledButtons";
import { NotificationContext } from "../components/NotificationProvider";
const styles = (theme) => ({
  wrapper: {
    padding: "1em 2em",
    fontFamily: "AirbnbCereal-Medium",
  },
  container: {
    color: "white",
    letterSpacing: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
  },
  text_field: {
    fontFamily: "AirbnbCereal-Book",
    width: "300px",
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
  text_font: {
    color: "#212b36",
    fontFamily: "AirbnbCereal-Book",
    fontSize: "1em",
  },
  create: {
    margin: "0px",
    marginTop: "30px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "12px",
    backgroundColor: "#e1f6e6",
    color: "#3b9c6c",
    "&:hover": {
      backgroundColor: "#b4e8c0",
    },
  },
  clear: {
    margin: "0px",
    marginTop: "30px",
    marginRight: "10px",
    padding: "10px 20px",
    borderRadius: "8px",
    fontFamily: "AirbnbCereal-Medium",
    fontSize: "12px",
    backgroundColor: "#fdecea",
    color: "#f44336",
    "&:hover": {
      backgroundColor: "#fbc6c2",
    },
  },
  formControl: {
    transitionDuration: "0.6s",
    padding: "0px 1px 0px 1px",
    margin: "20px 2px 2px 2px",
    backgroundColor: "#fff",
    "&:hover fieldset": {
      borderColor: "#212b36",
      transitionDuration: "0.6s",
    },
    "&:focus": {
      borderColor: "#212b36",
    },
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "50px",
    },
  },
});

const Contact = () => {
  const classes = useClasses(styles);
  const { showNotification } = useContext(NotificationContext);
  let navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [departments, setDepartments] = useState([
    "SOURCING",
    "PURCHASING",
    "ENGINEERING",
  ]);

  const [company, setCompany] = useState("");
  const [salesContact, setSalesContact] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [extension, setExtension] = useState("");
  const [department, setDepartment] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [remarks, setRemarks] = useState("");
  const [position, setPosition] = useState("");
  const [creator, setCreator] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (!isSessionValid) {
        invalidateCredentials();
        navigate("/");
      }
    };
    checkSession();

    axios
      .get(`${API_GATEWAY_URL}/company`, {
        params: {
          creator: Cookies.get("employee", null),
          role: Cookies.get("role", "SALES"),
        },
      })
      .then(function (response) {
        let english = [];
        for (let item of response.data) {
          english.push(item.english);
        }
        setOptions(english);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleCreate = () => {
    let last_updated = getToday();
    // create contact
    if (!salesContact || salesContact.trim().length == 0) {
      alert("Please ensure Contact Name is filled in");
      return;
    }
    axios
      .post(`${API_GATEWAY_URL}/contact`, {
        company: company.toUpperCase(),
        contact_name: salesContact.toUpperCase(),
        extension: extension,
        department: department,
        office_number: officeNumber,
        phone: mobileNumber,
        email: email.toUpperCase(),
        remarks: remarks ? remarks.trim() : "",
        position: position.toUpperCase(),
        creator: creator,
        last_updated: last_updated,
        updated_by: creator,
      })
      .then(function (response) {
        showNotification("success", "Successful Created / Updated Contact");
      })
      .catch(function (error) {
        showNotification("error", "Error processing request, please try again");
      });
  };

  const handleCompanySelect = (selectedCompany) => {
    axios
      .get(`${API_GATEWAY_URL}/contact`, {
        params: {
          company: selectedCompany,
        },
      })
      .then(function (response) {
        let contact_list = [];
        for (let contact of response.data) {
          contact_list.push(contact.contact_name);
        }
        console.log(contact_list);
        setContactPersonOptions(contact_list);
      })
      .catch(function (error) {
        showNotification(
          "error",
          "Error retrieving contact information, try again later"
        );
      });
  };

  const handleContactPersonChange = (event, input) => {
    let value = input.toUpperCase();
    setSalesContact(value);
    // only perform a search if the previous value is different
    if (selectedContact != value && contactPersonOptions.includes(value)) {
      setSelectedContact(value);
      // perform a search to retrieve the specific company options
      axios
        .get(`${API_GATEWAY_URL}/contact`, {
          params: {
            contact_name: value,
            company: company,
          },
        })
        .then((response) => {
          if (response.data.length !== 0) {
            let contact = response.data[0];
            setDepartment(contact.department);
            setOfficeNumber(contact.office_number);
            setEmail(contact.email);
            setRemarks(contact.remarks);
            setExtension(contact.extension);
            setMobileNumber(contact.phone);
            setPosition(contact.position);
            setCreatedBy(contact.creator);
            setCreatedOn(contact.created_on);
            setLastUpdated(contact.last_updated);
            setUpdatedBy(contact.updated_by);
          } else {
            // do something else here
            showNotification(
              "error",
              "Error retrieving contact information, try again later"
            );
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const reset = () => {
    setCompany("");
    setSalesContact("");
    setDepartment("");
    setOfficeNumber("");
    setExtension("");
    setMobileNumber("");
    setEmail("");
    setRemarks("");
    setPosition("");
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString.split(" ").reverse().join("-"));
    const formattedDate = date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .replace(",", "")
      .toLowerCase();
    return formattedDate;
  };

  return (
    <Box sx={{ fontFamily: "AirbnbCereal-Medium", padding: "0em 1em" }}>
      <StyledHeader>Contact Person</StyledHeader>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "1em",
        }}
      >
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                options={options}
                value={company}
                onInputChange={(e, value) => {
                  setCompany(value);
                }}
                onChange={(event, selectedValue) => {
                  if (selectedValue) {
                    handleCompanySelect(selectedValue);
                  }
                }}
                sx={{
                  fontFamily: "AirbnbCereal-Book",
                  fontSize: "1em",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontFamily: "AirbnbCereal-Book",
                        fontSize: "1em",
                      },
                    }}
                    label="Company Name"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <Autocomplete
              freeSolo
              options={contactPersonOptions}
              inputValue={salesContact}
              onInputChange={handleContactPersonChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      fontFamily: "AirbnbCereal-Book",
                      fontSize: "1em",
                    },
                  }}
                  label="Contact Name"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                autoWidth
                value={department}
                label="Department"
                onChange={(e) => setDepartment(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ fontFamily: "AirbnbCereal-Book", fontSize: "1em" }}
              >
                {departments.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Office Number"
              value={officeNumber}
              onChange={(e) => setOfficeNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Extension"
              value={extension}
              onChange={(e) => setExtension(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={4}>
            <StyledTextField
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12} spacing={2}>
          <Grid item xs={4} lg={2}>
            <StyledRedButton onClick={reset}>Clear</StyledRedButton>
          </Grid>
          <Grid item xs={8} lg={2}>
            <StyledGreenButton onClick={handleCreate}>
              Create / Update
            </StyledGreenButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "1em",
                paddingTop: "1em",
                color: "#1f2c37",
              }}
            >
              Created by: {createdBy ? createdBy : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "1em",
                paddingTop: "0.2em",
                color: "#1f2c37",
              }}
            >
              Created on: {createdOn ? convertDate(createdOn) : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "1em",
                paddingTop: "1em",
                color: "#1f2c37",
              }}
            >
              Last Update by: {updatedBy ? updatedBy : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "AirbnbCereal-Medium",
                fontSize: "1em",
                paddingTop: "0.2em",
                color: "#1f2c37",
              }}
            >
              Last Updated on: {lastUpdated ? convertDate(lastUpdated) : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
