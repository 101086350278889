import React, { useEffect, useState, useContext } from "react";
import { Button, Box } from "@mui/material";
import axios from "axios";
import { NotificationContext } from "../components/NotificationProvider";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { API_GATEWAY_URL, validateSession } from "../utils";
import Logo from "../assets/logo_slim.png"; // Adjust the path to your image file
import useClasses from "../useClasses";
import { StyledTextField } from "../components/StyledTextField";

const styles = (theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text_font: {
    fontFamily: "AirbnbCereal-Book",
    fontSize: "1.1em",
  },
});

const Login = () => {
  const classes = useClasses(styles);
  const { showNotification } = useContext(NotificationContext);
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const checkSession = async () => {
      const isSessionValid = await validateSession();
      if (isSessionValid) {
        navigate("/quotation/create");
      }
    };
    checkSession();
  }, []);

  const handleLogin = () => {
    if (email.length == 0 || password.length == 0) {
      showNotification("error", "Ensure fields are filled");
    } else {
      // perform standard login
      axios
        .post(`${API_GATEWAY_URL}/authentication/check-credentials`, {
          email,
          password,
        })
        .then((resp) => {
          Cookies.set("role", resp.data.role);
          Cookies.set("employee", resp.data.email.split("@")[0]);
          Cookies.set("email", email);
          Cookies.set("password", password);
          axios // generate session cookie
            .post(`${API_GATEWAY_URL}/authentication/create-session`, {
              email,
            })
            .then((resp_cookie) => {
              Cookies.set("wiselink_session", resp_cookie.data.cookie);
              navigate("/quotation/create");
            })
            .catch((error) => {
              showNotification("error", error.response.data);
            });
        })
        .catch((error) => {
          showNotification("error", error.response.data);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
      }}
    >
      <img
        src={Logo}
        alt="Description"
        width={"300px"}
        style={{ marginBottom: "1em" }}
      />
      <Box className={classes.container}>
        <StyledTextField
          label="Email"
          style={{ margin: "1.5em", width: "300px" }}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          InputProps={{
            className: classes.text_font,
          }}
        />
        <StyledTextField
          type="password"
          label="Password"
          style={{ marginBottom: "1.5em", width: "300px" }}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            className: classes.text_font,
          }}
        />
      </Box>
      <Button
        onClick={handleLogin}
        sx={{
          marginTop: "1em",
          transition: "0.3s",
          padding: "0.6em 4em 0.6em 4em",
          borderRadius: "8px",
          fontFamily: "AirbnbCereal-Bold",
          fontSize: "1em",
          color: "#fff",
          borderWidth: "1px",
          backgroundColor: "#f99d32",
          "&:hover": {
            backgroundColor: "#f45239",
            transition: "0.3s",
          },
        }}
      >
        Login
      </Button>
    </Box>
  );
};

export default Login;
