import { Modal, Box, TextField, Autocomplete, Grid } from "@mui/material";
import { StyledHeader } from "../../components/StyledHeader";
import {
  StyledOrangeButton,
  StyledRedButton,
} from "../../components/StyledButtons";
import { CancelOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const DeleteQuotationModal = (props) => {
  const {
    openQuotation,
    setOpenQuotation,
    qidOptions,
    qid,
    setQid,
    setSelectedQid,
    deleteQuotation,
    handleQuotationInputChange,
  } = props;
  return (
    <Modal
      open={openQuotation}
      onClose={() => {
        setOpenQuotation(false);
      }}
    >
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "87vh",
          borderRadius: "8px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "auto",
          width: "30%",
          padding: "50px 20px 50px 20px",
          backgroundColor: "#fff",
          border: "none",
          boxShadow: 24,
          "&:focus": {
            border: "none",
          },
        }}
      >
        <StyledHeader sx={{ margin: "0em", padding: "0em" }}>
          Delete Quotation
        </StyledHeader>
        <Autocomplete
          freeSolo
          options={qidOptions}
          inputValue={qid}
          onInputChange={handleQuotationInputChange}
          sx={{
            fontFamily: "AirbnbCereal-Book",
            fontSize: "1em",
            marginTop: "1.5em",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontFamily: "AirbnbCereal-Book",
                  fontSize: "1em",
                },
              }}
              label="Quotation No."
            />
          )}
        />
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "1em",
          }}
        >
          <Grid item lg={6}>
            <StyledOrangeButton
              onClick={deleteQuotation}
              startIcon={<DeleteOutlineIcon style={{ fontSize: "14px" }} />}
            >
              Delete Quotation
            </StyledOrangeButton>
          </Grid>
          <Grid item lg={6}>
            <StyledRedButton
              onClick={() => {
                setOpenQuotation(false);
                setQid("");
                setSelectedQid("");
              }}
              startIcon={<CancelOutlined style={{ fontSize: "14px" }} />}
            >
              Close
            </StyledRedButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
